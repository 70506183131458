/* File: src/styles/components/AudioPlayer.css */

/* Audio Player Container */
.audio-container {
    width: 100%;
    margin-top: var(--spacing-md);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Audio Player Styles */
.audio-player {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    margin: var(--spacing-md) 0;
    width: 100%;
    background: var(--color-background-dark);
}

/* Native Audio Element Styling */
audio {
    width: 100%;
    height: 44px;
    border-radius: 30px;
}

/* Webkit (Chrome, Safari, Edge) specific styles */
audio::-webkit-media-controls-panel {
    background: var(--color-background-dark);
    color: var(--color-text-primary);
    /* background-color: transparent !important; */
}

/* Make all controls white while preserving the dark background */
audio::-webkit-media-controls-enclosure {
    filter: invert(1) hue-rotate(180deg);
    background-color: transparent !important;
}

/* Override the inversion for the panel background */
audio::-webkit-media-controls-panel::before {
    background: var(--color-background-dark) !important;
    filter: invert(1) hue-rotate(180deg);
}

/* Audio Controls Customization */
.audio-player audio {
    filter: invert(1) hue-rotate(180deg);
    background-color: transparent;
}

.audio-player audio::-webkit-media-controls-panel {
    background-color: transparent;
}

.audio-player audio::-webkit-media-controls-current-time-display,
.audio-player audio::-webkit-media-controls-time-remaining-display {
    color: var(--color-text-primary);
}

/* Custom Focus State */
audio:focus {
    outline: 3px dashed var(--color-accent-primary);
}

.error-message {
    color: var(--color-accent-primary);
    font-size: 0.875rem;
    margin-left: var(--spacing-sm);
    font-family: var(--font-primary);
}
