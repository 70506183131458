.recommendations-container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
}

.recommendations-grid {
  display: grid;
  gap: 24px;
  margin-top: 20px;
  width: 100%;
}

/* Grid classes for different number of items */
.grid-1 {
  grid-template-columns: minmax(280px, 600px);
  justify-content: center;
}

.grid-2 {
  grid-template-columns: repeat(2, minmax(250px, 1fr));
}

.grid-3 {
  grid-template-columns: repeat(3, minmax(250px, 1fr));
}

.grid-4 {
  grid-template-columns: repeat(4, minmax(200px, 1fr));
}

.grid-many {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.recommendation-card {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.recommendation-card:hover {
  transform: translateY(-4px);
}

.recommendation-card-image {
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 100%; /* Sets aspect ratio to 1:1 (square) */
  overflow: hidden;
  border-radius: 12px;
}

.recommendation-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #ffffff;
  /* padding: 10px; */
}

.recommendation-card:hover .recommendation-card-image img {
  transform: scale(1.05);
}

.badge {
  position: absolute;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  z-index: 2;
}

.badge.bestseller {
  top: 10px;
  left: 10px;
  background-color: #ff9900;
  color: white;
}

.badge.amazon-choice {
  top: 10px;
  right: 10px;
  background-color: #232f3e;
  color: white;
}

.badge.prime {
  background-color: #00a8e1;
  color: white;
}

.badge.eco {
  background-color: #2da44e;
  color: white;
}

.recommendation-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.recommendation-card-overlay.visible {
  opacity: 1;
}

.quick-view-btn {
  padding: 20px 40px;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: transform 0.2s ease;
}

.quick-view-btn:hover {
  transform: scale(1.05);
}

.recommendation-card-content {
  padding: 16px;
}

.recommendation-card-header h3 {
  font-size: 16px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
  min-height: calc(1.2em * 4);
}

.recommendation-card-actions {
  display: flex;
  gap: 8px;
}

.action-btn {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #666;
  transition: color 0.2s ease;
}

.action-btn:hover {
  color: var(--primary-color);
}

.recommendation-card-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(0.5rem + 0.2vw);
}

.recommendation-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.recommendation-price .original-price {
  order: -1;
  margin-bottom: 4px;
  color: #999;
  font-size: 0.9em;
}

.current-price {
  font-size: calc(1rem + 0.2vw);
  font-weight: 600;
  color: var(--color-primary) !important;
}

.original-price {
  font-size: calc(0.85rem + 0.1vw);
  color: #cccccc;
  text-decoration: line-through;
}

.recommendation-rating {
  display: flex;
  align-items: center;
  gap: calc(0.2rem + 0.1vw);
}

.star-icon {
  color: #ffc107;
  font-size: calc(0.9rem + 0.1vw);
}

.num-ratings {
  font-size: calc(0.75rem + 0.1vw);
  color: #cccccc;
}

.delivery-info {
  font-size: calc(0.85rem + 0.1vw);
  color: #cccccc;
  min-height: calc(2.5rem + 0.5vw);
  width: 100%;
  max-width: 300px;
}

.delivery-info > div {
  width: 100%;
  overflow: hidden;
}

.delivery-info span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recommendation-buy-btn {
  display: block;
  /* width: 100%; */
  padding: calc(0.75rem + 0.3vw) calc(1rem + 0.5vw);
  text-align: center;
  background: var(--primary-cta-button-bg);
  text-decoration: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: calc(0.9rem + 0.1vw);
  transition: all 0.2s ease;
}

.recommendations-title {
  text-align: center;
  /* color: white; */
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
}

.recommendations-subtitle {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.recommendations-explanation {
  max-width: 800px;
  padding: 1rem;
  background-color: color-mix(in srgb, var(--color-primary) 20%, var(--color-text-primary) 5%);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.recommendations-explanation p {
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  margin: 0;
}

/* Quick View Modal Styles (kept here but modal is rendered via portal in ResultPage.js) */
.quick-view-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Increased to ensure it is on top */
}

.quick-view-content {
  background: black;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  overflow-y: auto;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  z-index: 2;
}

.quick-view-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 24px; */
}

.quick-view-images {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.quick-view-images img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 8px;
}

.quick-view-details {
  display: flex;
  flex-direction: column;
}

.quick-view-details h2 {
  font-size: 16px;
  margin: 0;
  color: #cccccc;
}

.quick-view-buy-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  margin-top: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.quick-view-buy-btn:hover {
  background-color: var(--primary-color-dark);
}

.price-rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-container {
  display: flex;
  flex-direction: column;
}

.rating-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.description, .details {
  border-top: 1px solid #eee;
  padding-top: 16px;
}

.description h3, .details h3 {
  font-size: 18px;
  margin: 0 0 8px 0;
  color: #cccccc;
}

.description p, .details p {
  margin: 0;
  color: #666;
  line-height: 1.6;
}

.product-meta {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.meta-item {
  font-size: 14px;
  color: #666;
}

.meta-item strong {
  color: #cccccc;
}

.delivery-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 24px;
}

.buy-now-btn, .add-to-cart-btn {
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.buy-now-btn {
  background: var(--action-button-bg);
  color: white;
  text-decoration: none;
}

.add-to-cart-btn {
  background: #f8f9fa;
  color: #cccccc;
  border: 1px solid #ddd;
}

.buy-now-btn:hover {
  background: var(--color-secondary);
}

.add-to-cart-btn:hover {
  background: #e9ecef;
}

.more-recommendations-btn {
  display: block;
  margin: 2rem auto;
  padding: 1rem 2rem;
  background-color: var(--color-background);
  color: var(--color-text);
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;
}

.more-recommendations-btn:disabled {
  background-color: var(--color-background);
  border-color: var(--color-border);
  cursor: not-allowed;
  opacity: 0.7;
}

.more-recommendations-btn:hover:not(:disabled) {
  background-color: var(--primary-color);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .recommendations-grid {
    gap: 12px;
  }

  .grid-1 {
    grid-template-columns: minmax(250px, 500px);
  }

  .grid-2, .grid-3, .grid-4, .grid-many {
    grid-template-columns: repeat(2, 1fr);
  }

  .recommendation-card {
    height: auto;
  }

  /* .recommendation-card-content {
    padding: 12px;
  } */

  .recommendation-card-header h3 {
    font-size: 14px;
  }

  .current-price {
    font-size: calc(0.9rem + 0.2vw);
  }

  .original-price {
    font-size: calc(0.8rem + 0.1vw);
  }

  .delivery-info {
    font-size: calc(0.8rem + 0.1vw);
    min-height: calc(2rem + 0.5vw);
  }

  .recommendation-buy-btn {
    padding: calc(0.6rem + 0.3vw) calc(0.8rem + 0.5vw);
    font-size: calc(0.85rem + 0.1vw);
  }
}

@media screen and (max-width: 480px) {

  .current-price {
    font-size: calc(0.85rem + 0.2vw);
  }

  .original-price {
    font-size: calc(0.75rem + 0.1vw);
  }

  .delivery-info {
    font-size: calc(0.75rem + 0.1vw);
    min-height: calc(1.8rem + 0.5vw);
  }

  .recommendation-buy-btn {
    padding: calc(0.5rem + 0.3vw) calc(0.7rem + 0.5vw);
    font-size: calc(0.8rem + 0.1vw);
  }
}

@media screen and (max-width: 350px) {
  .recommendations-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
