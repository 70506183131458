
.my-profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    margin-top: 70px;
    padding: 20px;
    box-sizing: border-box;
}

.profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
}

.profile-image-container {
    position: relative;
    margin-bottom: 20px;
}

.profile-image-wrapper {
    position: relative;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--color-accent);
    box-shadow: 0 10px 30px rgba(206, 69, 203, 0.3);
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edit-profile-image {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: var(--color-accent);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.profile-name {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0 5px;
}

.profile-email {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px;
}

.profile-sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    max-width: 600px;
}

.profile-section {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(206, 69, 203, 0.2);
    border-radius: var(--border-radius);
    padding: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.profile-section-icon {
    background: var(--color-secondary);
    color: var(--color-accent);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 20px;
    font-size: 24px;
}

.profile-section-content {
    flex-grow: 1;
}

.profile-section-content h3 {
    margin: 0 0 10px;
    font-size: 18px;
}

.profile-section-content p {
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
}

.logout-button {
    margin-top: 30px;
    background: var(--color-accent);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.upload-status, .error-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: var(--border-radius);
    text-align: center;
}

.upload-status {
    background: rgba(0, 255, 0, 0.1);
    color: var(--color-accent);
}

.error-message {
    background: rgba(255, 0, 0, 0.1);
    color: red;
}

@media (max-width: 768px) {
    .profile-sections {
        grid-template-columns: 1fr;
    }
}
