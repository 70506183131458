/* Landing Page */
.landing-page {
  font-family: var(--font-primary);
  min-height: 100vh;
  background: var(--color-background);
  color: var(--color-text-primary);
  overflow-x: hidden;
  position: relative;
}

/* Hero Section */

.display-heading {
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subheading {
  font-size: clamp(1.1rem, 2vw, 1.5rem);
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

.hero-cta {
  display: flex;
  gap: var(--spacing-xl);
  justify-content: center;
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}


.gradient-sphere {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, 
      var(--color-accent-primary) 0%,
      var(--color-accent-secondary) 30%,
      transparent 70%
  );
  opacity: 0.15;
  filter: blur(60px);
  animation: pulse 8s ease-in-out infinite;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); opacity: 0.15; }
  50% { transform: scale(1.1); opacity: 0.2; }
}

/* Stats Section */
.stats-section {
  background: linear-gradient(135deg, rgba(255, 102, 170, 0.1) 0%, rgba(255, 153, 204, 0.1) 100%);
  border: 1px solid rgba(255, 102, 170, 0.15);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  padding: var(--spacing-lg);
  margin: var(--spacing-lg) 0;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  gap: var(--spacing-lg);
}

.stat-item {
  text-align: center;
  transition: var(--transition-smooth);
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-number {
  font-size: 2.5rem;
  font-weight: var(--font-weight-bold);
  color: #fff;
  margin-bottom: var(--spacing-xxs);
}

.stat-item h3 {
  font-size: clamp(2rem, 4vw, 3rem);
  color: var(--color-accent-primary);
  margin-bottom: var(--spacing-xs);
}

.stat-item p {
  font-size: 1.1rem;
}

/* Demo Video Section */
.demo-video {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(255, 102, 170, 0.15);
  margin: var(--spacing-lg) 0;
}

.video-container {
  /* max-width: 1000px; */
  height: 80vh;
  margin: 0 auto;
  position: relative;
}

.video-container.shorts-video {
  position: relative;
  width: 315px;
  height: 560px;
  margin: 0 auto;
  background: #000;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--shadow-medium);
}

.video-container.shorts-video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.video-container.shorts-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  background: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.video-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: rgba(255, 255, 255, 0.9);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: auto;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-subtle);
}

.play-button svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  color: var(--color-accent-primary);
}

.play-button:hover {
  background: #fff;
  transform: translate(-50%, -50%) scale(1.1);
}

@media (max-width: 768px) {
  .video-container.shorts-video {
    width: 280px;
    height: 498px;
  }
}

/* Features Section */
.features-section {
  padding: var(--spacing-md) max(var(--spacing-md), 5vw);
  margin: auto;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-soft);
  padding: 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(178, 97, 244, 0.2);
}

.feature-card .icon-wrapper {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, var(--color-secondary), var(--color-accent));
  border-radius: var(--border-radius-round);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  transition: transform 0.3s ease;
}

.feature-card:hover .icon-wrapper {
  transform: scale(1.1);
}

.feature-card .icon-wrapper i {
  font-size: 2rem;
  color: var(--color-text-primary);
}

.feature-card h3 {
  color: var(--color-secondary);
  margin-bottom: 1rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.feature-card:hover h3 {
  color: var(--color-accent);
}

.feature-card p {
  color: var(--color-text-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.feature-btn {
  background: var(--secondary-cta-button-bg);
  color: var(--color-text-primary);
  padding: 0.8rem 1.5rem;
  border-radius: var(--border-radius-default);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: auto;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-subtle);
}

.feature-card:hover .feature-btn {
  background: var(--color-accent);
  box-shadow: var(--shadow-medium);
}

.feature-btn i {
  transition: transform 0.3s ease;
}

.feature-card:hover .feature-btn i {
  transform: translateX(4px);
}

/* How It Works Section */
.how-it-works {
  margin: 0.5rem;
  background: linear-gradient(180deg, 
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 102, 171, 0.05) 100%
  );
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 1.5rem;
  padding: 0 var(--spacing-lg);
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius-soft);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.step-image-container {
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
}

.step-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: var(--border-radius-soft);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  transition: transform 0.3s ease;
}

.step:hover .step-image {
  transform: scale(1.05);
}

.step-number {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 40px;
  height: 40px;
  background: var(--color-accent-primary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.step h3 {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1rem 0;
  color: var(--color-text-primary);
}

.step p {
  font-size: 1rem;
  color: var(--color-text-secondary);
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 968px) {
  .steps-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .steps-container {
    grid-template-columns: 1fr;
  }
  
  .step {
    padding: 1.5rem;
  }
  
  .step-image {
    height: 180px;
  }
}

/* Testimonials Section */
.testimonials-section {
  padding: var(--spacing-lg) max(var(--spacing-lg), 5vw);
}

.testimonials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-card {
  background: linear-gradient(135deg, rgba(255, 102, 170, 0.1) 0%, rgba(255, 153, 204, 0.1) 100%);
  border: 1px solid rgba(255, 102, 170, 0.15);
  border-radius: 15px;
  padding: var(--spacing-lg);
  margin: var(--spacing-md);
  transition: var(--transition-smooth);
}

.testimonial-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 15px 35px rgba(255, 102, 170, 0.2);
}

.rating {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #FFD700 !important; /* Golden yellow color */
  letter-spacing: 2px;
}

.testimonial-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-md);
  font-style: italic;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.author-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.testimonial-card:hover .author-image {
  transform: scale(1.05);
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-info strong {
  color: var(--color-text-primary);
  font-size: 1.1rem;
  font-weight: 600;
}

.author-info span {
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

/* Pricing Section */
.pricing-section {
  padding: var(--spacing-lg) max(var(--spacing-lg), 5vw);
  background: linear-gradient(180deg, 
      transparent 0%,
      rgba(255, 102, 171, 0.05) 50%,
      transparent 100%
  );
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
  /* color: #fff; */
}

.pricing-card {
  background: linear-gradient(135deg, rgba(255, 102, 170, 0.1) 0%, rgba(255, 153, 204, 0.1) 100%);
  border: 1px solid rgba(255, 102, 170, 0.15);
  border-radius: 15px;
  padding: var(--spacing-lg);
  transition: var(--transition-smooth);
  height: 100%;
}

.pricing-card.popular {
  transform: scale(1.05);
  border: 2px solid var(--color-accent);
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(255, 102, 170, 0.25);
}

.price {
  margin: var(--spacing-md) 0;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 5px;
  color: var(--color-text-primary);
}

.currency {
  font-size: 1.5rem;
  color: var(--color-text-primary);
}

.amount {
  font-size: 3rem;
  font-weight: bold;
  color: var(--color-text-primary) !important;
}

.period {
  color: var(--color-text-secondary);
}

.features-list {
  list-style: none;
  padding: 0;
  margin: var(--spacing-lg) 0;
}

.features-list li {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  color: var(--color-text-secondary);
}

.check-icon {
  color: var(--color-accent-primary);
}

/* Subscription Section */
.subscription-section {
  padding: var(--spacing-xl) 0;
  color: black;
}

.subscription-section .section-title,
.subscription-section .section-subtitle {
  color: black;
  text-shadow: none;
}

.subscription-section .form-control {
  color: black;
  background: var(--color-background-light);
  border: 1px solid var(--color-border);
}

.subscription-section .form-control::placeholder {
  color: var(--color-text-secondary);
}

/* FAQ Section */


.faq-grid {
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  gap: 20px;
}

.faq-item {
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.faq-question {
  width: 100%;
  padding: 24px 30px;
  background: transparent;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-question:hover {
  color: var(--color-text-secondary);
}

.faq-question svg {
  /* color: #718096; */
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.faq-answer {
  padding: 0 30px 24px;
  color: #4a5568;
  line-height: 1.6;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .faq-section {
    padding: 0px 0px;
  }
  
  .faq-section .section-title {
    font-size: 2rem;
    margin-bottom: 40px;
  }
  
  .faq-question {
    padding: 20px 24px;
    font-size: 1rem;
  }
  
  .faq-answer {
    padding: 0 24px 20px;
    font-size: 0.95rem;
  }
}

/* App Download Section */
.app-download {
  /* padding: 100px 20px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 60px;
  max-width: 1400px;
  margin: 0 auto;
}

.download-image {
  flex: 1;
  max-width: 50%;
  position: relative;
}

.download-image img {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.download-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.download-content .pre-heading {
  display: inline-block;
  padding: 8px 16px;
  background: rgba(255, 102, 171, 0.1);
  color: var(--color-primary);
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.download-content h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.download-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.app-stats {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.stat .rating, .stat .downloads {
  font-size: 1.2rem;
  font-weight: 700;
  color: yellow;
}

.stat span:last-child {
  font-size: 0.9rem;
  color: var(--color-text-secondary);
}

.store-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.store-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  /* background: #2d3748; */
  /* color: white; */
  text-decoration: none;
  transition: all 0.3s ease;
}

.store-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(45, 55, 72, 0.15);
}
/* 
.store-button.apple {
  background: #000000;
}

.store-button.google {
  background: #2d3748;
} */

.store-button svg {
  font-size: 1.8rem;
}

.button-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.2;
}

.button-text span {
  font-size: 0.7rem;
  opacity: 0.9;
}

.button-text strong {
  font-size: 1.1rem;
  font-weight: 600;
}

.download-note {
  font-size: 0.9rem;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .app-download {
    flex-direction: column-reverse;
    /* padding: 60px 20px; */
    gap: 40px;
  }

  .download-image {
    max-width: 80%;
  }

  .download-image img {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .download-image {
    max-width: 100%;
  }

  .download-image img {
    max-height: 300px;
  }
}

/* Final CTA Section */
.final-cta {
  padding: var(--spacing-lg) max(var(--spacing-lg), 5vw);
  text-align: center;
}

/* Footer */
.footer {
  background: linear-gradient(135deg, var(--color-background-dark) 0%, var(--color-background-light) 100%);
  padding: var(--spacing-xl) 0;
  margin-top: var(--spacing-xl);
}

.landing-footer {
  padding: var(--spacing-xl) var(--spacing-lg);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: var(--spacing-lg);
}

.footer-section h3 {
  color: var(--color-text-primary);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 0.8rem;
}

.footer-section ul li a {
  color: var(--color-text-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: var(--color-text-primary);
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: var(--spacing-md);
  margin-top: var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-bottom p {
  margin: 0;
  color: var(--color-text-secondary);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: var(--color-text-secondary);
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--color-text-primary);
}

@media (max-width: 768px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .footer-section h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .footer-section ul li {
    margin-bottom: 0.6rem;
  }
  
  .footer-section ul li a {
    font-size: 0.9rem;
  }
  
  .footer-bottom {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    font-size: 0.9rem;
  }
  
  .social-links a {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .landing-footer {
    padding: var(--spacing-lg) var(--spacing-md);
  }
}

.landing-page .btn-primary:hover {
  box-shadow: 0 8px 16px rgba(255, 102, 171, 0.2);
}

.landing-page .btn-outline {
  background: transparent;
  border: 2px solid var(--color-accent-primary);
  color: var(--color-text-primary);
}

.landing-page .btn-outline:hover {
  background: rgba(255, 102, 171, 0.1);
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .steps-container {
    flex-direction: column;
    align-items: center;
  }

  .step:not(:last-child)::after {
    display: none;
  }

  .app-download {
    flex-direction: column;
    text-align: center;
  }

  .store-buttons {
    justify-content: center;
  }
}

@media (max-width: 768px) {

  .hero-cta {
    justify-content: center;
  }


  .stats-container {
    flex-direction: column;
    align-items: center;
  }

  .features-grid,
  .testimonials,
  .pricing-grid {
    grid-template-columns: 1fr;
  }

  .store-buttons {
    flex-direction: column;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .btn {
    width: 90%;
  }

  .btn-full {
    width: 100%;
  }

  .footer-bottom {
    flex-direction: column;
    gap: var(--spacing-md);
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }

  .pricing-card.popular {
    transform: scale(1);
  }
  
  .stat-item {
    margin-bottom: var(--spacing-md);
  }
  
  .play-button {
    width: 60px;
    height: 60px;
  }
  
  .store-button {
    display: block;
    margin: var(--spacing-sm) auto;
  }
}

/* Dark Mode Enhancement */
@media (prefers-color-scheme: dark) {
  .feature-card,
  .testimonial-card,
  .pricing-card,
  .faq-question {
    background: rgba(255, 255, 255, 0.03);
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.animate-fadeIn {
  animation: fadeIn 0.6s ease-out;
}

.animate-slideUp {
  animation: slideUp 0.6s ease-out;
}

/* RTL Support */
.rtl .landing-page {
  direction: rtl;
}

.rtl .footer-link:hover {
  transform: translateX(-5px);
}


.leaderboard-preview {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboard-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
}

.top-rank {
  background: var(--bg-card);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 320px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);
  cursor: pointer;
  outline: none;
}

.top-rank:hover, .top-rank:focus {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-color: var(--primary-color);
}

.top-rank:active {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.rank-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-shrink: 0;
}

.rank-icon.gold {
  background: linear-gradient(135deg, #ffd700 0%, #ffb347 100%);
  color: #fff;
}

.rank-icon.silver {
  background: linear-gradient(135deg, #C0C0C0 0%, #A9A9A9 100%);
  color: #fff;
}

.rank-icon.bronze {
  background: linear-gradient(135deg, #cd7f32 0%, #b36a23 100%);
  color: #fff;
}

.rank-user {
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1;
}

.user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid var(--border-color);
}

.rank-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.user-info {
  text-align: left;
}

.user-info h4 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-primary);
}

.user-title {
  display: block;
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-top: 2px;
}

.score-info {
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.score {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-primary);
}

.score-label {
  display: flex;
  align-items: center;
}

.top-rank:nth-child(1) .score-label {
  color: #ffd700;
  filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.3));
}

.top-rank:nth-child(2) .score-label {
  color: #C0C0C0;
  filter: drop-shadow(0 0 2px rgba(192, 192, 192, 0.3));
}

.top-rank:nth-child(3) .score-label {
  color: #cd7f32;
  filter: drop-shadow(0 0 2px rgba(205, 127, 50, 0.3));
}

.leaderboard-cta .btn {
  padding: 12px 30px;
  font-size: 1.1rem;
}
