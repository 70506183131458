/* File: src/styles/components/VideoContainer.css */

/* Video Container Styles */
.video-container-wrapper {
  overflow: hidden;
}

.video-container {
  position: relative;
  margin: auto;
  justify-content: center;
  display: flex;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

/* Video and Captured Image Styling */
.video-element {
  object-fit: cover;
  aspect-ratio: 9 / 16;
  transform: scaleX(-1);
}

.captured-image {
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

/* Countdown Overlay */
.countdown-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.countdown-overlay h1 {
  font-size: 5rem;
  margin: 0;
  color: #FFFFFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
}

/* Scan Overlay and Line Animation */
.scan-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.scan-line {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(206, 181, 255), rgba(255, 0, 0, 0));
  box-shadow: 0 0 10px rgb(176, 108, 255);
  animation: scan 3s ease-in-out infinite alternate;
}

@keyframes scan {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}

/* Floating Buttons Styling */
.floating-buttons {
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  z-index: 3;
}

.floating-buttons .btn {
  color: white;
  font-size: 1.5rem;
  backdrop-filter: blur(5px);
  /* background-color: rgba(229, 184, 255, 0.24); */
}

/* Floating Event Selection Styling */
.floating-event-selection {
  position: absolute;
  top: 15%; /* Position it below the CTA container */
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  padding: 10px;
  backdrop-filter: blur(5px);
  border-radius: 5px;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.floating-event-selection .page-select {
  color: #FFFFFF;
  font-size: 1em;
  background-color: rgba(229, 184, 255, 0.24);
  border: 1px solid #FFFFFF;
  padding: 8px 12px;
  border-radius: 5px;
  width: auto;
}

/* Accessibility Focus States */
.floating-buttons .btn:focus,
.floating-event-selection .page-select:focus,
.scan-line:focus {
  outline: 3px dashed #FF69B4;
}

/* Image Slider for Captured Images */
.image-slider {
  min-height: var(--min-video-container-height);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.image-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(229, 184, 255, 0.24);
  border: none;
  color: white;
  padding: 8px;
  border-radius: 50%;
  width: 40px; /* Set equal width and height for circular shape */
  height: 40px;
  cursor: pointer;
  z-index: 2;
}

.slider-button.left {
  left: 10px;
}

.slider-button.right {
  right: 10px;
}


/* Event Selection Options */
.event-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.event-grid .cta-step {
  width: 100%;
  text-align: center;
}

.cta-step {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: white;
  outline: none;
  width: auto;
  display: inline-block;
}

.cta-step.cancel {
  background-color: #ff6b6b;
  color: white;
  border-color: #ff6b6b;
}

.cta-step:hover {
  background-color: #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.floating-camera-button {
  background-color: var(--color-background-floating-button);
  width: 40px; /* Set equal width and height for circular shape */
  height: 40px;
  z-index: 4;
  font-size: 1.2rem;
  padding: 8px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Camera Switch Button */
.floating-camera-button:hover {
  background: rgba(80, 80, 80, 0.8);
  transform: scale(1.1);
  color: var(--color-background);
}

.floating-camera-button svg {
  width: 20px;
  height: 20px;
  color: var(--color-primary);
}

/* Camera Toggle Button */
.camera-toggle-button {
  position: absolute;
  top: 10px;
  right: 1rem;
}

.camera-switch-button {
  position: absolute;
  top: 10px;
  right: 4rem;
}

/* Camera Info Message */
.camera-info {
  /* display: flex; */
  /* flex-direction: column; */
  justify-content: center;
  text-align: left;
  padding: 2rem;
  padding-bottom: 0;
  padding-top: 6rem;
  max-width: 600px;
  min-height: var(--min-video-container-height);
  height: 100%;
  margin: 0 auto;
}

.camera-info h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-accent-primary);
}

.camera-info p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.camera-info ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.camera-info li {
  margin: 0.5rem 0;
  font-weight: 500;
}

