/* Define CSS Variables */
:root {
  /* Consolidated Color Palette */
  --color-primary: rgb(237, 54, 255); /* Vibrant pink */
  --color-secondary: rgb(178, 97, 244); /* Soft purple */
  --color-text-primary: #000000; /* Pure black */
  --color-text-secondary: #666666; /* Pure black */
  --color-background-dark: #ffffff;
  --color-background-light: #ffffff; /* Light pink background */
  --color-background: color-mix(in srgb, var(--color-background-dark) 70%, var(--color-background-light) 30%);
  --color-background-floating-button: rgba(229, 184, 255, 0.24);
  
  /* Accent Colors - derived from primary/secondary */
  --color-accent: rgb(184, 90, 255); /* Blend of primary and secondary */
  --color-accent-primary: var(--color-primary); /* Using primary */
  --color-accent-secondary: var(--color-secondary); /* Using secondary */
  --color-accent-highlight: #ff8aff; /* Lighter version of primary */

  /* Action Button Colors */
  --primary-cta-button-bg: linear-gradient(135deg, var(--color-accent-primary), var(--color-accent-secondary));
  --secondary-cta-button-bg: var(--color-secondary);

  /* Gradients */
  --gradient-background: linear-gradient(135deg, var(--color-background-dark) 0%, var(--color-background) 100%);
  
  /* Border Radius */
  --border-radius-default: 30px;
  --border-radius-soft: 12px;
  --border-radius-round: 50%;
  
  /* Video Container Heights */
  --min-video-container-height: 70vh;
  --video-container-height: 90%;

  /* Typography */
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  
  /* Spacing and Sizing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  
  /* Shadows */
  --shadow-subtle: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-medium: 0 10px 20px rgba(0, 0, 0, 0.15);
}
