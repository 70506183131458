.cta-primary,
.cta-secondary {
    background: var(--primary-cta-button-bg);
    border: none;
    padding: 0.5rem 0;
    cursor: pointer;
    transition: all 0.2s ease-out;
    width: inherit;
    font-size: 12px;
    text-transform: uppercase;
    overflow: hidden;
    display: block;
    margin: auto;
    position: relative;
    z-index: 1;
    border-radius: var(--border-radius-default);
    box-shadow: var(--shadow-subtle);
  }

  .cta-secondary {
    background: var(--secondary-cta-button-bg);
    /* background: color-mix(in srgb, var(--color-primary) 50%, white); */
  }
  
  .cta-primary::before,
  .cta-secondary::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    z-index: -1;
  }
  
  .cta-primary:hover, 
  .cta-secondary:hover {
    background: var(--color-secondary);
    transform: translateY(-1px);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
  }

/* Confirmation and CTA Container */
.cta-container {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: inherit;
    padding: var(--spacing-sm);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius-soft);
    box-shadow: var(--shadow-medium);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  /* Multi-Step CTA and Image Confirmation Styles */
.cta-step button {
  max-width: 80vw;
  background: var(--primary-cta-button-bg);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-step button:hover {
  background-color: rgba(80, 80, 80, 0.8);
  transform: scale(1.05);
}

.photo-instructions {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
    text-align: left;
}

.photo-instructions li {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
    position: relative;
}

.photo-instructions li:before {
    content: "•";
    position: absolute;
    left: 0.5rem;
    color: #666;
}