.about-us-page {
  padding: 4rem 0;
}

.about-us-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.about-us-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.mission-section,
.vision-section,
.team-section {
  background: var(--background-light);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.mission-section:hover,
.vision-section:hover,
.team-section:hover {
  transform: translateY(-5px);
}

.mission-section h2,
.vision-section h2,
.team-section h2 {
  color: var(--primary-color);
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}

.mission-section p,
.vision-section p,
.team-section p {
  color: var(--text-primary);
  line-height: 1.6;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .about-us-page {
    padding: 2rem 0;
  }
  
  .mission-section,
  .vision-section,
  .team-section {
    margin-bottom: 1.5rem;
  }
}
