.documentation-page {
  min-height: 100vh;
  background: var(--background-light);
}

.documentation-page .sidebar {
  background: white;
  padding: 2rem;
  border-right: 1px solid var(--border-color);
  height: 100vh;
  position: sticky;
  top: 0;
}

.documentation-page .main-content {
  padding: 3rem;
  max-width: 900px;
}

.documentation-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.documentation-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.sidebar .nav-link {
  color: var(--text-secondary);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.sidebar .nav-link:hover {
  background: var(--primary-light);
  color: var(--primary-color);
}

.sidebar .nav-link.active {
  background: var(--primary-color);
  color: white;
}

.documentation-page section {
  margin-bottom: 4rem;
}

.documentation-page h2 {
  color: var(--text-primary);
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--border-color);
}

.documentation-page .card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.documentation-page .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.documentation-page .card h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.documentation-page .card p {
  color: var(--text-secondary);
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .documentation-page .sidebar {
    height: auto;
    position: static;
    margin-bottom: 2rem;
  }
  
  .documentation-page .main-content {
    padding: 2rem;
  }
}
