.subscriptions-page {
  min-height: 100vh;
  padding: 4rem 2rem;
}

.subscriptions-header {
  text-align: center;
  margin-bottom: 4rem;
}

.subscriptions-header h1 {
  font-size: 3rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.subscriptions-header p {
  color: #666;
  font-size: 1.2rem;
}

/* Billing Toggle */
.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
}

.billing-toggle span {
  color: #666;
  cursor: pointer;
}

.billing-toggle span.active {
  color: #6366f1;
  font-weight: 600;
}

.toggle-switch {
  width: 50px;
  height: 24px;
  background: #e2e8f0;
  border-radius: 12px;
  padding: 2px;
  cursor: pointer;
  position: relative;
}

.toggle-handle {
  width: 20px;
  height: 20px;
  background: #6366f1;
  border-radius: 50%;
}

.savings {
  background: #4ade80;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

/* Plans Container */
.plans-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.plan-card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.plan-card.pro {
  border: 2px solid #6366f1;
}

.plan-card.pro::before {
  content: 'Most Popular';
  position: absolute;
  top: 1rem;
  right: -2rem;
  background: #6366f1;
  color: white;
  padding: 0.5rem 3rem;
  transform: rotate(45deg);
  font-size: 0.8rem;
  font-weight: 600;
}

.plan-card h2 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.price {
  margin: 2rem 0;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.currency {
  font-size: 1.5rem;
  color: #4a5568;
}

.amount {
  font-size: 3rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0 0.2rem;
}

.period {
  color: #666;
}

.features {
  margin: 2rem 0;
  text-align: left;
}

.features li {
  margin-bottom: 1rem;
  color: #4a5568;
  display: flex;
  align-items: center;
}

.features i {
  color: #4ade80;
  margin-right: 0.5rem;
}

.subscribe-button {
  width: 100%;
  padding: 1rem;
  background: #6366f1;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subscribe-button:hover {
  background: #4f46e5;
  transform: translateY(-2px);
}

/* Guarantee Section */
.guarantee {
  text-align: center;
  margin: 4rem 0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.guarantee i {
  color: #6366f1;
  font-size: 1.5rem;
}

/* FAQ Section */
.faq {
  max-width: 1200px;
  margin: 4rem auto 0;
}

.faq h2 {
  text-align: center;
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.faq-item {
  padding: 0.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-item h3 {
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .subscriptions-page {
    padding: 1rem 1rem;
  }

  .subscriptions-header h1 {
    font-size: 2rem;
  }

  .plans-container {
    grid-template-columns: 1fr;
  }
}
