/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Language Selector Styles */
.language-selector {
    position: relative;
    display: inline-block;
}

.language-dropdown-toggle {
    background-color: #f1f1f1;
    color: #333;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.language-dropdown-menu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 4px;
}

.language-selector:hover .language-dropdown-menu {
    display: block;
}

.language-dropdown-menu button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    border: none;
    background: none;
    width: 100%;
}

.language-dropdown-menu button:hover {
    background-color: #f1f1f1;
}

.language-dropdown-menu button.active {
    background-color: #e0e0e0;
    font-weight: bold;
}

/* Navigation Menu Styles */
header nav ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 10px 0;
    background-color: #f1f1f1;
}

header nav ul li {
    margin: 0 10px;
}

header nav ul li a {
    text-decoration: none;
    color: #333;
    padding: 10px;
    display: inline-block;
    transition: background-color 0.3s ease;
}

header nav ul li a:hover {
    background-color: #e0e0e0;
}
