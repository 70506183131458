/* File: src/styles/components/CommonPage.css */

/* Fade-In and Fade-Out Classes */
.fade-in {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

.fade-out {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
}


/* Additional Styling for Main Container */

/* Page Description Styling */
.page-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center;
    color: var(--color-text-secondary);
    text-shadow: 0 0 10px rgba(255, 102, 170, 0.4);
}

/* Modern More button styles */
.more-recommendations-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 24px;
    margin: 2rem auto;
    background-color: rgba(255, 102, 170, 0.15);
    border: 2px solid var(--color-accent-primary);
    color: var(--color-accent-primary);
    border-radius: 50px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 300px;
    width: 100%;
}

.more-recommendations-btn:hover {
    background-color: var(--color-accent-primary);
    color: white;
    box-shadow: 0 0 20px rgba(255, 102, 170, 0.4);
}

.more-recommendations-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.more-recommendations-btn svg {
    width: 24px;
    height: 24px;
    stroke: currentColor;
}

.magic-wand-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.magic-wand {
    width: 50px;
    height: 50px;
    animation: 
        spin 2s linear infinite,
        float 1s ease-in-out infinite alternate;
    transform-origin: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes float {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

.magic-wand .wand-tip {
    animation: sparkle 1s infinite alternate;
}

@keyframes sparkle {
    0% {
        opacity: 0.6;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1.1);
    }
}

.magic-wand .wand-base,
.magic-wand .wand-center {
    animation: shimmer 1.5s infinite alternate;
}

@keyframes shimmer {
    0% {
        fill-opacity: 0.7;
    }
    100% {
        fill-opacity: 1;
    }
}

/* Optional: Additional Styling as Needed */
