.security-page {
  padding: 4rem 0;
}

.security-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.security-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.security-card {
  height: 100%;
  border: none;
  border-radius: var(--radius-lg);
  padding: 2rem;
  text-align: center;
  background: var(--background-light);
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
}

.security-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-md);
}

.feature-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-light);
  border-radius: 50%;
  margin: 0 auto 1.5rem;
}

.security-card .card-title {
  color: var(--text-primary);
  font-size: var(--font-size-xl);
  margin-bottom: 1rem;
}

.certification-grid {
  margin-top: 2rem;
}

.certification-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: white;
  border-radius: var(--radius-md);
  margin-bottom: 1rem;
}

.check-icon {
  color: var(--primary-color);
  margin-right: 1rem;
  font-size: var(--font-size-lg);
}

.practice-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.practice-list li {
  padding: 1rem 0;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-secondary);
  padding-left: 2rem;
  position: relative;
}

.practice-list li:last-child {
  border-bottom: none;
}

.practice-list li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--primary-color);
  font-weight: bold;
}

@media (max-width: 768px) {
  .security-page {
    padding: 2rem 0;
  }
  
  .security-card {
    margin-bottom: 1.5rem;
  }
}
