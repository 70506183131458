.api-page {
  min-height: 100vh;
  background: var(--background-light);
}

.api-page .sidebar {
  background: white;
  padding: 2rem;
  border-right: 1px solid var(--border-color);
  height: 100vh;
  position: sticky;
  top: 0;
}

.api-nav h3 {
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

.api-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.api-nav li {
  margin-bottom: 0.5rem;
}

.api-nav a {
  color: var(--text-secondary);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-md);
  display: block;
  transition: var(--transition-fast);
}

.api-nav a:hover {
  background: var(--primary-light);
  color: var(--primary-color);
}

.main-content {
  padding: 3rem;
}

.api-page h1 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.api-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.api-card {
  border: none;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  margin-bottom: 1.5rem;
}

.endpoint-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.method {
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius-sm);
  font-size: var(--font-size-sm);
  font-weight: 600;
  text-transform: uppercase;
}

.method.get {
  background: #E3F2FD;
  color: #1976D2;
}

.method.post {
  background: #E8F5E9;
  color: #388E3C;
}

.path {
  font-family: monospace;
  font-size: var(--font-size-md);
  color: var(--text-primary);
}

.code-block {
  background: #2D3748;
  color: #E2E8F0;
  padding: 1.5rem;
  border-radius: var(--radius-md);
  overflow-x: auto;
  margin: 1rem 0;
}

.code-block code {
  font-family: monospace;
}

.sdk-card {
  height: 100%;
  border: none;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
}

.sdk-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.sdk-card h3 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .api-page .sidebar {
    height: auto;
    position: static;
    margin-bottom: 2rem;
  }
  
  .main-content {
    padding: 2rem 1rem;
  }
}
