.features-page {
  padding: 4rem 0;
}

.features-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.features-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.features-grid {
  margin-top: 3rem;
}

.feature-card {
  border: none;
  border-radius: 16px;
  padding: 0.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  background: var(--background-light);
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-light);
  border-radius: 50%;
  margin: 0 auto 1.5rem;
}

.feature-card .card-title {
  color: var(--text-primary);
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.feature-card .card-text {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .features-page {
    padding: 2rem 0;
  }
  
  .feature-card {
    margin-bottom: 1.5rem;
  }
}
