/* File: src/styles/components/ShareButton.css */

/* Share Button Container */
.share-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.share-button:hover {
    background: var(--color-primary);
    transform: translateY(-2px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

.share-button:active {
    transform: translateY(0);
}

.share-icon {
    display: flex;
    align-items: center;
    color: #FF69B4;
    transition: transform 0.3s ease;
}

.share-button:hover .share-icon {
    transform: scale(1.1);
}

.share-text {
    font-weight: 600;
    letter-spacing: 0.025em;
}

/* Responsive styles */
@media (max-width: 768px) {
    .share-button {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}


/* Share Section */
.share-section {
    margin: 1rem;
    text-align: center;
}

.share-section button {
    background: var(--color-primary);
    border: none;
    padding: 1rem 2rem;
    border-radius: var(--border-radius-default);
    font-size: 1.1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: stretch;
    gap: 1rem;
    width: 100%;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.share-section button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 200, 255, 0.3);
}

.share-icon {
    font-size: 1.2rem;
}