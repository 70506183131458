.shop-result {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.shop-summary {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.summary-text {
    font-size: 1.2em;
    color: #2c3e50;
    margin-bottom: 16px;
}

.style-notes {
    border-top: 1px solid #e9ecef;
    padding-top: 16px;
}

.style-notes h3 {
    color: #343a40;
    margin-bottom: 8px;
}

.top-picks {
    margin-bottom: 24px;
}

.picks-list {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.pick-item {
    background: #e9ecef;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 0.9em;
    color: #495057;
}

.items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
}

.item-card {
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.item-header h3 {
    font-size: 1.1em;
    color: #212529;
    margin: 0;
    flex: 1;
}

.price-range {
    background: #28a745;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
    margin-left: 12px;
}

.brand-match {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.score-label {
    margin-right: 8px;
    color: #6c757d;
}

.score-value {
    font-weight: bold;
    color: #007bff;
}

.item-explanation {
    margin-bottom: 16px;
    color: #495057;
    font-size: 0.95em;
}

.categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-bottom: 16px;
}

.category-score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-radius: 6px;
}

.category-label {
    color: #495057;
    font-size: 0.9em;
}

.score {
    font-weight: bold;
    color: #007bff;
}

.search-keywords {
    border-top: 1px solid #e9ecef;
    padding-top: 16px;
}

.search-keywords h4 {
    color: #343a40;
    margin-bottom: 8px;
    font-size: 0.95em;
}

.keywords-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.keyword {
    background: #e9ecef;
    color: #495057;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.85em;
}
