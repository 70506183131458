.pricing-page {
  padding: 4rem 0;
}

.pricing-page h1 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.pricing-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 2rem;
}

/* Pricing Toggle */
.pricing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
}

.pricing-toggle .active {
  color: var(--primary-color);
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--text-secondary);
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.discount-badge {
  background: var(--primary-light);
  color: var(--primary-color);
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius-sm);
  font-size: var(--font-size-sm);
  margin-left: 0.5rem;
}

/* Pricing Cards */
.pricing-cards {
  margin-top: 3rem;
}

.pricing-card {
  height: 100%;
  border: none;
  border-radius: var(--radius-lg);
  padding: 0.5rem;
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
  position: relative;
  overflow: hidden;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--shadow-md);
}

.pricing-card.popular {
  border: 2px solid var(--primary-color);
  transform: scale(1.05);
}

.popular-badge {
  position: absolute;
  top: 1rem;
  right: -2rem;
  background: var(--primary-color);
  color: var(--color-primary);
  padding: 1rem 3rem;
  transform: rotate(45deg);
  font-size: var(--font-size-sm);
}

.plan-name {
  color: var(--text-primary);
  font-size: var(--font-size-2xl);
  margin-bottom: 0.5rem;
}

.plan-description {
  color: var(--text-secondary);
  margin-bottom: 2rem;
}

.price {
  font-size: var(--font-size-4xl);
  color: var(--text-primary);
  margin-bottom: 2rem;
}

.price .currency {
  font-size: var(--font-size-xl);
  vertical-align: super;
}

.price .period {
  font-size: var(--font-size-md);
  color: var(--text-secondary);
}

.custom-price {
  font-size: var(--font-size-2xl);
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.features-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--text-secondary);
}

.check-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}

/* FAQ Section */
.faq-section {
  margin-top: 5rem;
}

.faq-card {
  border: none;
  border-radius: var(--radius-lg);
  margin-bottom: 1rem;
  box-shadow: var(--shadow-sm);
}

.faq-card h3 {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  margin-bottom: 0.5rem;
}

.faq-card p {
  color: var(--text-secondary);
  margin: 0;
}

@media (max-width: 992px) {
  .pricing-card.popular {
    transform: none;
  }
  
  .pricing-card {
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .pricing-page {
    padding: 2rem 0;
  }
}
