/* Design Tokens */

.outfit-history-page {
    min-height: 100vh;
    color: var(--text-light);
    font-family: 'Inter', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 80px;
}

.page-container {
    padding: 24px;
    /* max-width: 800px; */
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.page-header {
    margin-bottom: 24px;
    text-align: center;
}

.page-content {
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
}

.filter-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    border-radius: 15px;
}

.search-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

.search-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-light);
    opacity: 0.5;
}

.search-input {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    background: var(--color-background-floating-button);
    border: 2px solid transparent;
    border-radius: 10px;
    color: var(--text-light);
    font-size: 1rem;
    transition: all 0.3s ease;
}

.search-input:focus {
    border-color: var(--color-background-floating-button);
    outline: none;
    box-shadow: 0 0 15px rgba(0, 200, 255, 0.3);
}

.filter-dropdowns {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.filter-dropdowns select {
    flex: 1;
    padding: 0.75rem;
    background: var(--color-background-floating-button);
    color: var(--text-light);
    border: none;
    border-radius: 10px;
    appearance: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.filter-dropdowns select:hover {
    background: var(--color-background-floating-button);
}

.results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.result-card {
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.result-image-container {
    height: 250px;
    overflow: hidden;
}

.result-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.result-card:hover .result-image {
    transform: scale(1.1);
}

.no-image-placeholder {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(40, 40, 60, 0.5);
    color: var(--text-light);
    font-size: 1rem;
    opacity: 0.7;
}

.result-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    color: var(--text-light);
    opacity: 0.7;
    font-size: 0.8rem;
}

.result-meta span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.result-text {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 0.75rem;
    line-height: 1.4;
    color: var(--text-light);
    height: 4.3rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: height 0.3s ease;
    margin: 0;
}

.result-text::after {
    content: "↓";
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--color-background-floating-button);
    padding: 0 8px;
    font-size: 12px;
}

.result-text.expanded {
    height: auto;
    max-height: none;
}

.result-text.expanded::after {
    content: "↑";
}

.load-more-btn {
    display: block;
    width: 200px;
    margin: 2rem auto 0;
    padding: 1rem;
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.load-more-btn:hover {
    box-shadow: 0 10px 20px rgba(0, 200, 255, 0.4);
    transform: translateY(-5px);
}

.no-results {
    text-align: center;
    color: var(--text-light);
    opacity: 0.6;
    padding: 2rem;
    font-size: 1.2rem;
}

.outfit-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.delete-button {
    background: none;
    border: none;
    color: #ff4d4d;
    cursor: pointer;
    padding: 8px;
    transition: color 0.2s ease;
    opacity: 0.7;
}

.delete-button:hover {
    color: #ff0000;
    opacity: 1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .filter-dropdowns {
        flex-direction: column;
    }

    .results-grid {
        grid-template-columns: 1fr;
    }
}
