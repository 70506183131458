/* resultPage.css */

.result-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #0a0a0a 0%, #1a1a2e 100%);
    color: #ffffff;
    padding: 2rem;
    /* margin-top: 30px; */
    position: relative; /* Ensures no transforms or tricky contexts */
}

.content-container {
    /* max-width: 1200px; */
    /* margin: 0 auto; */
    background: rgba(255, 255, 255, 0.03);
    border-radius: 24px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Loading Animation */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

.loading-icon {
    font-size: 3rem;
    color: #FF69B4;
}

/* Result Card */
.result-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Image Gallery */
.image-gallery {
    position: relative;
    margin-bottom: 2rem;
    border-radius: 16px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.2);
}

.result-image {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    display: block;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 200, 255, 0.2);
    border: none;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
}

.nav-button:hover {
    background: rgba(0, 200, 255, 0.4);
}

.nav-button.left {
    left: 1rem;
}

.nav-button.right {
    right: 1rem;
}

/* Result Details */
.result-details {
    padding: 1rem;
}

.result-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.05);
    padding: 1.5rem;
    border-radius: 12px;
    margin-bottom: 1.5rem;
    height: 60px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.result-text::after {
    content: "↓";
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(to left, rgba(26, 26, 46, 0.9) 30%, transparent);
    padding: 0.5rem;
    font-size: 1rem;
}

.result-text.expanded {
    height: auto;
}

.result-text.expanded::after {
    content: "↑";
}

/* Audio Section */
.audio-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 1rem;
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.audio-icon {
    font-size: 1.5rem;
    color: #FF69B4;
}

.audio-icon.playing {
    animation: pulse 1.5s infinite;
}

/* More Recommendations Button */
.more-recommendations-btn {
    margin: 2rem auto 0 auto;
    display: block;
    color: #333;
    border: 1px solid #ddd;
    padding: 1rem 2rem;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
}

.more-recommendations-btn:hover {
    background: #e9ecef;
}

/* Animations */
@keyframes pulse {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.7;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .result-card {
        padding: 1rem;
    }

    .nav-button {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 480px) {
    .result-page {
        padding: 1rem;
    }

    .result-text {
        font-size: 1rem;
        padding: 1rem;
    }

    .share-section button {
        padding: 0.8rem 1.5rem;
        font-size: 1rem;
    }
}
