.press-page {
  padding: 4rem 0;
}

.press-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.press-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.press-card {
  border: none;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
}

.press-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.release-date {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-bottom: 0.5rem;
}

.press-card .card-title {
  color: var(--text-primary);
  font-size: var(--font-size-xl);
  margin-bottom: 1rem;
}

.source {
  color: var(--primary-color);
  font-size: var(--font-size-sm);
  margin-top: 1rem;
}

.resource-card {
  height: 100%;
  border: none;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
}

.resource-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.resource-card h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.resource-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.resource-card li {
  color: var(--text-secondary);
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
}

.resource-card li:before {
  content: '→';
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .press-page {
    padding: 2rem 0;
  }
  
  .resource-card {
    margin-bottom: 1.5rem;
  }
}
