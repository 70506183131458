/* File: src/styles/components/TopMenu.css */

:root {
    --menu-background: var(--color-background-dark);
    --menu-text-color: #ffffff;
    --menu-hover-color: var(--color-primary);
}

.top-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    backdrop-filter: blur(20px);
    box-shadow: 0 4px 15px var(--menu-hover-color);
    transition: all 0.3s ease;
}

/* Desktop Menu Styles */
.desktop-menu {
    /* height: 60px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menu-section {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.menu-section.page-description-container {
    text-align: center;
    position: absolute;
    left: 40%;
    margin: 0 auto;
    flex: 0 0 auto;
}

.menu-section.page-description-container.mobile {
    position: absolute;
    left: 45%;
    transform: translateX(-50%);
    text-align: center;
    margin: 0;
    flex: 0 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50%;
}

.menu-section.page-description-container.mobile .page-description {
    font-size: 0.9em;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: var(--menu-text-color);
    opacity: 0.6;
    transition: all 0.3s ease;
    position: relative;
}

.menu-item.active,
.menu-item:hover {
    opacity: 1;
}

.menu-item::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--color-accent-primary);
    transition: width 0.3s ease;
}

.menu-item.active::after,
.menu-item:hover::after {
    width: 100%;
}

.menu-icon {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
}

.menu-title {
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.image-icon {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.3rem;
}

.page-description {
    margin: 0;
    font-size: 1.1em;
    color: var(--text-color);
}

/* Language Selector */
.language-selector {
    position: relative;
}

.language-toggle {
    display: flex;
    align-items: center;
    background: var(--color-background-floating-button);
    border: 1px solid rgba(255, 102, 170, 0.25);
    border-radius: 30px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.language-toggle:hover {
    background: var(--color-background-light);
    box-shadow: 0 0 15px rgba(255, 102, 170, 0.3);
}

.language-flag {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

.language-code {
    font-weight: 600;
    color: var(--menu-text-color);
}

.language-options {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 0.5rem;
    background: linear-gradient(135deg, rgba(31, 15, 26, 0.95) 0%, rgba(74, 31, 58, 0.95) 100%);
    border: 1px solid rgba(255, 102, 170, 0.25);
    border-radius: 15px;
    padding: 0.5rem;
    box-shadow: 0 4px 15px rgba(255, 102, 170, 0.2);
    backdrop-filter: blur(20px);
    z-index: 1000;
    min-width: 150px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
}

.language-options.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.language-option {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 10px;
}

.language-option:hover {
    background: linear-gradient(135deg, rgba(255, 102, 170, 0.15) 0%, rgba(255, 153, 204, 0.15) 100%);
}

.language-option .language-flag {
    margin-right: 1rem;
    font-size: 1.5rem;
}

.language-option .language-name {
    color: var(--menu-text-color);
}

/* Language Selector Dropdown */
.language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--menu-background);
    border-radius: 8px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 200px;
}

.language-dropdown.mobile {
    right: 0;
    left: auto;
}

[dir="rtl"] .language-dropdown {
    right: auto;
    left: 0;
}

[dir="rtl"] .language-dropdown.mobile {
    right: auto;
    left: 0;
}

@media (max-width: 768px) {
    .language-dropdown {
        right: 0;
        left: auto;
    }
    
    [dir="rtl"] .language-dropdown {
        left: 0;
        right: auto;
    }
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 1rem;
}

.action-button {
    background: none;
    border: none;
    color: var(--menu-text-color);
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.2rem;
}

.action-button:hover {
    opacity: 1;
    color: var(--color-accent-primary);
}

/* Mobile Menu Styles */
.mobile-menu {
    flex-direction: column;
}

.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    position: relative;
}

.mobile-header .mobile-menu-toggle {
    order: 1;
    z-index: 1;
}

.mobile-header .language-toggle {
    order: 2;
    z-index: 1;
}

[dir="rtl"] .mobile-header {
    flex-direction: row-reverse;
}

[dir="rtl"] .mobile-header .mobile-menu-toggle {
    order: 2;
}

[dir="rtl"] .mobile-header .language-toggle {
    order: 1;
}

.mobile-menu-toggle {
    background: none;
    border: none;
    color: var(--menu-text-color);
    font-size: 1.5rem;
    cursor: pointer;
}

.mobile-menu-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: var(--menu-background);
    backdrop-filter: blur(20px);
    padding: 1rem;
}

[dir="rtl"] .mobile-menu-dropdown {
    right: 0;
    left: auto;
}

.mobile-menu-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mobile-action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.mobile-action-buttons .action-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .top-menu {
        padding: 0.5rem 1rem;
    }

    .desktop-menu {
        display: none;
    }

    .mobile-menu {
        display: flex;
    }
}

@media (min-width: 769px) {
    .mobile-menu {
        display: none;
    }
}
