/* stylemeterResults.css */

.stylemeter-results {
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.summary-container {
    max-width: 800px;
    margin: 0 auto 3rem;
    padding: 1.5rem;
    background: color-mix(in srgb, var(--color-background) 50%, transparent);
    border-radius: 16px;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.6;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.stylemeter-results-grid {
    display: grid;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;
    align-items: start;
    /* width: 100%; */
}

.grid-1 {
    grid-template-columns: minmax(300px, 600px);
}

.grid-2 {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
}

.grid-3 {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
}

.grid-4 {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
}

@media (max-width: 768px) {
    .stylemeter-results-grid {
        gap: 0.75rem;
        /* padding: 0.5rem; */
    }

    .grid-1 {
        grid-template-columns: minmax(250px, 500px);
    }

    .grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-3, .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .stylemeter-results-grid {
        gap: 0.5rem;
    }

    .grid-1 {
        grid-template-columns: minmax(200px, 400px);
    }
}

.person-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    color: var(--color-text-primary);
    min-height: 400px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #000000;
}

.person-card.winner {
    border: 2px solid #FFD700;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}

.person-card.runner-up {
    border: 2px solid #C0C0C0;
    box-shadow: 0 0 20px rgba(192, 192, 192, 0.3);
}

.person-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.person-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.person-label {
    padding: 0.6rem 1.2rem;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 25px;
    font-weight: 600;
    font-size: 1rem;
    color: var(--color-text-primary);
    letter-spacing: 0.5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.image-container {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #f5f5f5;
}

.original-image-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.image-card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    aspect-ratio: 1;
}

.image-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.original-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.image-card:hover .original-image {
    transform: scale(1.05);
}

.badge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-weight: 600;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    z-index: 10;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
}

.badge-icon {
    font-size: 0.8rem;
    display: inline-block;
    vertical-align: middle;
}

.badge.winner {
    background: linear-gradient(135deg, #FFD700, #FFA500);
    color: #000;
    border: 2px solid #FFD700;
    box-shadow: 0 4px 15px rgba(255, 215, 0, 0.3);
}

.badge.winner .badge-icon {
    color: #000;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.badge.runner-up {
    background: linear-gradient(135deg, #E0E0E0, #B0B0B0);
    color: #000;
    border: 2px solid #C0C0C0;
    box-shadow: 0 4px 15px rgba(192, 192, 192, 0.3);
}

.badge.runner-up .badge-icon {
    color: #000;
    text-shadow: 0 0 10px rgba(192, 192, 192, 0.5);
}

.badge:hover {
    transform: translateY(-2px) scale(1.02);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
}

.badge:hover .badge-icon {
    transform: scale(1.1) rotate(5deg);
    transition: transform 0.3s ease;
}

.overall-score {
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(45deg, #FF69B4, #6A11CB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 0.5rem 0;
}

.explanation {
    min-height: 60px;
    margin-bottom: 1.5rem;
    color: var(--color-text-primary);
    font-size: 0.95rem;
    line-height: 1.4;
}

.category {
    margin-bottom: 1rem;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease;
    background: var(--color-background);
    padding: 1rem;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.category:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 4px;
    margin: -4px -4px 12px -4px;
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    color: #fff;
}

.category-name {
    font-size: 0.9rem;
}

.category-score {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--color-text-secondary);
}

.progress-bar {
    height: 8px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    background: linear-gradient(90deg, #FF69B4, #6A11CB);
    border-radius: 4px;
    transition: width 1s ease-out;
}

.style-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.slider-container {
    width: 100%;
    
}

.mySwiper {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.slider-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.swiper-button-next,
.swiper-button-prev {
    color: #ffffff;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background 0.3s ease;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background: rgba(0, 0, 0, 0.8);
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 18px;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
}

.swiper-pagination-bullet-active {
    background: #000000;
    opacity: 1;
}

.stylemeter-results-content {
    max-width: 100vw;
    width: 100%;
    margin: auto;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-x: hidden;
}

.top-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.bottom-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 30px 0;
    padding: 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.error-container {
    text-align: center;
    padding: 2rem;
    background: rgba(255, 0, 0, 0.1);
    border-radius: 12px;
    margin: 2rem auto;
    max-width: 600px;
}

.category-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.improve-button {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.8rem;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.improve-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.improvement-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 0.8rem;
    font-size: 0.9rem;
    color: white;
    z-index: 100;
    max-width: 250px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.improvement-modal {
    position: fixed;
    bottom: 40vh;
    left: 10%;
    transform: translate(-50%, -50%);
    background: var(--color-background);
    border-radius: 16px;
    padding: 1rem;
    z-index: 9999;
    width: 70%;
    backdrop-filter: blur(10px);
    max-height: 90vh;
    max-width: 500px;
    border: 2px solid var(--color-accent-primary);
    border-radius: var(--border-radius-soft);
    color: var(--color-text-primary);
    box-shadow: var(--shadow-medium);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #fff;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.modal-button {
    margin: 1rem auto;
    display: block;
    background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
    border: none;
    border-radius: 8px;
    padding: 0.8rem 1.5rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.modal-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.3);
}

.modal-close {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
}

.modal-close:hover {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

/* Modal Styles */
.modal-overlay {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
}

.modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--color-accent-primary);
    background: linear-gradient(135deg, var(--color-accent-primary), var(--color-accent-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modal-content {
    margin-bottom: 1.5rem;
    line-height: 1.6;
    color: var(--color-text-secondary);
    font-size: 1rem;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.modal-button {
    padding: 0.75rem 1.5rem;
    border-radius: var(--border-radius-soft);
    cursor: pointer;
    transition: all 0.3s ease;
}

.modal-button.modal-close {
    border: 1px solid var(--color-accent-primary);
    background: transparent;
    color: var(--color-text-primary);
    
}

.modal-button:not(.modal-close) {
    border: none;
    background: var(--primary-cta-button-bg);
    color: var(--color-text-primary);
    box-shadow: var(--shadow-subtle);
}

/* Toast Notification */
.coming-soon-toast {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    min-width: 300px;
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);
    border: 2px solid var(--color-accent-primary);
    border-radius: var(--border-radius-soft);
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: var(--shadow-medium);
}

.toast-icon {
    background: var(--color-accent-primary);
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius-round);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.toast-content {
    flex: 1;
}

.toast-title {
    display: block;
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    background: linear-gradient(135deg, var(--color-accent-primary), var(--color-accent-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.toast-message {
    margin: 0;
    color: var(--color-text-secondary);
    font-size: 0.9rem;
}

.category.clickable {
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.category.clickable:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.info-icon {
    margin-left: 8px;
    font-size: 0.9em;
    color: var(--color-text-secondary);
    opacity: 0.8;
    transition: opacity 0.2s ease;
}

.category.clickable:hover .info-icon {
    opacity: 1;
}

@media (max-width: 1200px) {
    .stylemeter-results-grid {
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .stylemeter-results-grid {
        gap: 0.75rem;
        /* padding: 0.5rem; */
    }

    .person-card {
        padding: 0.75rem;
        min-height: unset;
    }

    .overall-score {
        font-size: calc(2rem + 1vw);
        margin: 0.25rem 0;
    }

    .category {
        padding: 0.75rem;
        margin-bottom: 0.75rem;
        font-size: 0.9rem;
    }

    .category-score {
        font-size: 1rem;
    }

    .score-bar {
        height: 6px;
        margin: 0.35rem 0;
    }

    .badge {
        padding: 0.35rem 0.7rem;
        font-size: 0.7rem;
    }

    .person-label {
        padding: 0.4rem 0.8rem;
        font-size: 0.85rem;
    }

    .image-container {
        height: auto;
        aspect-ratio: 3/4;
    }
}

@media (max-width: 480px) {
    .stylemeter-results-grid {
        gap: 0.5rem;
    }

    .person-card {
        padding: 0.3rem;
    }

    .overall-score {
        font-size: calc(1.75rem + 1vw);
    }

    .category {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
    }

    .category-score {
        font-size: 0.9rem;
    }

    .score-bar {
        height: 4px;
        margin: 0.25rem 0;
    }

    .badge {
        padding: 0.25rem 0.5rem;
        font-size: 0.65rem;
    }

    .person-label {
        padding: 0.3rem 0.6rem;
        font-size: 0.8rem;
    }
}
