.leaderboard-page-container {
    padding: 24px;
    margin: 0 auto;
    margin-top: 50px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.leaderboard-load-more-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    color: var(--color-text-secondary);
    font-size: 16px;
}

.leaderboard-load-more-spinner svg {
    font-size: 20px;
}

.leaderboard-no-more-results {
    text-align: center;
    padding: 20px;
    color: var(--color-text-secondary);
    font-style: italic;
}

.leaderboard-rank-badge {
    display: flex;
    align-items: center;
    gap: 8px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    width: fit-content;
}

.leaderboard-rank-icon {
    font-size: 24px;
}

.leaderboard-rank-icon.gold {
    color: #FFD700;
}

.leaderboard-rank-icon.silver {
    color: #C0C0C0;
}

.leaderboard-rank-icon.bronze {
    color: #CD7F32;
}

.leaderboard-rank-number {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-text-primary);
}

.leaderboard-categories-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
}

.leaderboard-category-box {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.leaderboard-category-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leaderboard-category-code {
    font-weight: 500;
    color: var(--color-text-primary);
}

.leaderboard-category-score-container {
    width: 100%;
}

.leaderboard-gauge-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.leaderboard-gauge-bar-bg {
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    overflow: hidden;
}

.leaderboard-gauge-bar-fill {
    height: 100%;
    border-radius: 3px;
    transition: width 0.3s ease;
}

.leaderboard-gauge-value {
    font-size: 1.2em;
    font-weight: 500;
    color: var(--color-text-primary);
}

.leaderboard-progress-bar {
    width: 100%;
    height: 8px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.leaderboard-progress-fill {
    height: 100%;
    background: linear-gradient(45deg, #FF69B4, #6A11CB);
    border-radius: 4px;
    position: relative;
}

.leaderboard-progress-fill::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    animation: leaderboard-shimmer 2s infinite;
}

@keyframes leaderboard-shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

/* View Toggle */
.leaderboard-view-toggle {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin: 16px 0;
}

.leaderboard-toggle-btn {
    background: rgba(255, 255, 255, 0.05);
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    color: var(--color-text-secondary);
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.leaderboard-toggle-btn:hover {
    background: rgba(255, 255, 255, 0.1);
}

.leaderboard-toggle-btn.active {
    background: rgba(255, 255, 255, 0.15);
    color: var(--color-text-primary);
}

.leaderboard-toggle-btn svg {
    font-size: 0.9em;
    opacity: 0.8;
}

.leaderboard-toggle-btn.active svg {
    opacity: 1;
}

/* Table View */
.leaderboard-table-container {
    overflow-x: auto;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 12px;
    /* padding: 16px; */
}

.leaderboard-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    margin-top: -8px;
    table-layout: fixed;
    color: var(--color-text-primary);
    overflow: auto;
}

.leaderboard-table th {
    text-align: left;
    padding: 12px;
    font-weight: 500;
    color: var(--color-text-secondary);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.leaderboard-table th:first-child {
    width: 100px;
}

.leaderboard-table th:last-child {
    width: 120px;
    text-align: right;
}

.leaderboard-outfit-row {
    background: rgba(255, 255, 255, 0.03);
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.leaderboard-outfit-row:hover {
    background: rgba(255, 255, 255, 0.05);
}

.leaderboard-outfit-row.expanded {
    background: rgba(255, 255, 255, 0.05);
    border-bottom: none;
}

.leaderboard-outfit-row td {
    padding: 8px 4px;
    vertical-align: middle;
}

.leaderboard-outfit-row td:last-child {
    text-align: right;
}

.leaderboard-rank-cell {
    width: 100px;
}

.leaderboard-table-badge {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    border-radius: 20px;
    width: fit-content;
}

.leaderboard-table-outfit-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
}

.leaderboard-table-outfit-image {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    object-fit: cover;
    flex-shrink: 0;
}

.leaderboard-table-overall-score {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    white-space: nowrap;
}

.leaderboard-score-gauge {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    font-size: 1.1em;
    min-width: 80px;
    justify-content: center;
}

.leaderboard-gauge-icon {
    font-size: 1.1em;
    opacity: 0.8;
}

.leaderboard-expand-icon {
    margin-left: 12px;
    opacity: 0.6;
    font-size: 0.9em;
    transition: transform 0.2s ease;
    width: 16px;
    flex-shrink: 0;
}

.leaderboard-categories-row td {
    padding: 0;
}

.leaderboard-categories-diagram {
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    margin: 0 12px 16px;
}

.leaderboard-category {
    margin-bottom: 1rem;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 12px;
}

.leaderboard-category:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 4px;
    margin: -4px -4px 12px -4px;
}

.leaderboard-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    color: var(--color-text-secondary);
}

.leaderboard-category-name {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.leaderboard-info-icon {
    font-size: 0.8em;
    opacity: 0.6;
    cursor: help;
    transition: opacity 0.2s ease;
}

.leaderboard-info-icon:hover {
    opacity: 1;
}

.leaderboard-category-score {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--color-text-primary);
}

/* Grid View */
.leaderboard-grid-container {
    display: grid;
    grid-template-columns: minmax(300px, 800px);
    gap: 2rem;
    padding: 24px 0;
    max-width: 800px;
    margin: 0 auto;
}

.leaderboard-grid-item {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    color: var(--color-text-primary);
    min-height: 400px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid #000000;
}

.leaderboard-grid-item.winner {
    border: 2px solid #FFD700;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}

.leaderboard-grid-item.runner-up {
    border: 2px solid #C0C0C0;
    box-shadow: 0 0 20px rgba(192, 192, 192, 0.3);
}

.leaderboard-grid-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leaderboard-grid-rank {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    width: fit-content;
}

.leaderboard-grid-image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.leaderboard-grid-score {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    width: fit-content;
}

.leaderboard-grid-categories {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.leaderboard-grid-categories .category {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 12px;
    transition: all 0.2s ease;
}

.leaderboard-grid-categories .category:hover {
    background: rgba(255, 255, 255, 0.08);
}

.leaderboard-grid-categories .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.leaderboard-grid-categories .category-name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

.leaderboard-grid-categories .info-icon {
    font-size: 14px;
    opacity: 0.7;
    cursor: help;
}

.leaderboard-grid-categories .category-score {
    font-weight: 500;
}

.leaderboard-grid-categories .progress-bar {
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    overflow: hidden;
}

.leaderboard-grid-categories .progress-fill {
    height: 100%;
    background: var(--color-primary);
    border-radius: 3px;
}

@media (max-width: 768px) {
    .leaderboard-grid-container {
        grid-template-columns: 1fr;
        gap: 1rem;
        margin: 0;
        /* width: 100%; */
    }

    .leaderboard-grid-item {
        padding: 1rem;
        gap: 1rem;
        min-height: unset;
        border-radius: 16px;
    }

    .leaderboard-grid-header {
        gap: 8px;
        flex-wrap: wrap;
    }

    .leaderboard-grid-rank,
    .leaderboard-grid-score {
        padding: 6px 12px;
        font-size: 14px;
    }

    .leaderboard-grid-categories {
        gap: 8px;
    }

    .leaderboard-grid-categories .category {
        padding: 8px;
    }

    .leaderboard-grid-categories .category-header {
        margin-bottom: 6px;
    }

    .leaderboard-grid-categories .category-name {
        font-size: 14px;
    }

    .leaderboard-grid-categories .category-score {
        font-size: 14px;
    }

    .leaderboard-grid-image {
        border-radius: 8px;
    }
}

@media (max-width: 480px) {
    .leaderboard-grid-item {
        padding: 12px;
        gap: 12px;
    }
}

@media (max-width: 768px) {
    .leaderboard-category {
        padding: 0.75rem;
        margin-bottom: 0.75rem;
    }

    .leaderboard-category:hover {
        padding: 4px;
        margin: -4px -4px 8px -4px;
    }

    .leaderboard-category-score {
        font-size: 1rem;
    }

    .leaderboard-progress-bar {
        height: 6px;
    }
}

@media (max-width: 480px) {
    .leaderboard-category {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .leaderboard-category-name {
        font-size: 0.8rem;
    }

    .leaderboard-category-score {
        font-size: 0.9rem;
    }

    .leaderboard-progress-bar {
        height: 4px;
    }
}
