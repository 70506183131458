.loading-spinner.magic-wand-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.magic-wand {
    width: 50px;
    height: 50px;
    animation: wandSpin 2s infinite linear;
}

.wand-tip,
.wand-base,
.wand-center {
    animation: glowEffect 1.5s infinite alternate;
}

@keyframes wandSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes glowEffect {
    from {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
}
