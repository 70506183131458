.privacy-policy {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  color: #1a1a1a;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.last-updated {
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
  font-style: italic;
}

.privacy-policy section {
  margin-bottom: 2rem;
}

.privacy-policy h2 {
  color: #2d3748;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #6366f1;
}

.privacy-policy h3 {
  color: #4a5568;
  font-size: 1.2rem;
  margin: 1rem 0;
}

.privacy-policy p {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.privacy-policy li {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.contact-info {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 1rem;
  }

  .privacy-policy h1 {
    font-size: 2rem;
  }

  .privacy-policy h2 {
    font-size: 1.3rem;
  }
}
