/* File: src/styles/login.css */

/* Login Page Container */
.login-page {
  font-family: var(--font-primary);
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.login-container {
  max-width: 500px;
  width: 100%;
  background: linear-gradient(135deg, rgba(255, 102, 170, 0.1) 0%, rgba(255, 153, 204, 0.1) 100%);
  border: 1px solid rgba(255, 102, 170, 0.15);
  border-radius: 15px;
  padding: var(--spacing-lg);
  backdrop-filter: blur(10px);
}

.mode-selection {
  text-align: center;
}

.mode-selection h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, var(--color-accent-primary) 0%, var(--color-accent-secondary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.mode-description {
  color: var(--color-text-secondary);
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.local-mode-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.local-mode-card:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-2px);
}

.mode-icon {
  font-size: 2rem;
  color: var(--color-accent-primary);
  background: rgba(255, 102, 170, 0.1);
  padding: 1rem;
  border-radius: 12px;
}

.mode-content {
  text-align: left;
  flex: 1;
}

.mode-content h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.mode-content p {
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

.start-button {
  background: linear-gradient(135deg, var(--color-accent-primary) 0%, var(--color-accent-secondary) 100%);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.divider span {
  padding: 0 1rem;
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

.google-section {
  text-align: center;
}

.google-section h4 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.google-section p {
  color: var(--color-text-secondary);
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.google-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: 100%;
}

.google-button-container > div {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
}

.google-button-container iframe {
  margin: 0 auto !important;
}

.login-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.4);
}

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  width: 100%;
}

.login-header .logo {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
  object-fit: contain;
}

.login-header h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #FF69B4;
}

.login-header p {
  color: #a0a0a0;
  font-size: 1rem;
}

.login-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  align-items: center;
}

.login-action-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.google-button-container {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.google-button-container > div {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-disclaimer {
  color: #a0a0a0;
  font-size: 0.8rem;
  text-align: center;
  max-width: 300px;
}

.login-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.login-description h2 {
  color: #FF69B4;
  margin-bottom: 1rem;
}

.login-description > p {
  color: #e0e0e0;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1000px;
}

.feature-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.feature-card:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.03);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.feature-card h4 {
  color: #FF69B4;
  margin-bottom: 0.5rem;
}

.feature-card p {
  color: #a0a0a0;
  font-size: 0.9rem;
}

.details-toggle {
  background-color: transparent;
  border: 2px solid #FF69B4;
  color: #FF69B4;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.details-toggle:hover {
  background-color: rgba(255, 105, 180, 0.1);
}

.data-details {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
}

.data-details h3 {
  color: #FF69B4;
  margin-bottom: 1rem;
}

.data-details ul {
  list-style-type: none;
  padding: 0;
}

.data-details li {
  color: #a0a0a0;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.data-details li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #FF69B4;
}

.language-selector {
  display: flex;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 5px;
  backdrop-filter: blur(10px);
  z-index: 10;
}

.language-selector button {
  background-color: transparent;
  border: none;
  color: #a0a0a0;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.language-selector button:hover {
  color: #FF69B4;
  background-color: rgba(255, 105, 180, 0.1);
}

.language-selector button.active {
  color: #FF69B4;
  font-weight: bold;
  background-color: rgba(255, 105, 180, 0.2);
}

.login-description-text,
.login-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.login-description-text {
  margin-bottom: 20px;
}

.login-features {
  margin-top: 20px;
}

.custom-login-cta {
  width: 100%;
  max-width: 300px;
  padding: 15px 25px;
  background: linear-gradient(135deg, #FF69B4, #FF33CC);
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(255, 105, 180, 0.3);
  position: relative;
  overflow: hidden;
}

.custom-login-cta::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent);
  transition: all 0.5s ease;
}

.custom-login-cta:hover::before {
  left: 100%;
}

.custom-login-cta:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(255, 105, 180, 0.4);
}

.custom-login-cta .cta-icon {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.custom-login-cta:hover .cta-icon {
  transform: translateX(5px);
}

.custom-login-button {
  width: 100%;
  max-width: 300px;
  padding: 15px 25px;
  background: linear-gradient(135deg, #FF69B4, #FF33CC);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.custom-login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 105, 180, 0.3);
}

.custom-login-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .login-page {
    padding: 1rem;
  }

  .login-container {
    padding: var(--spacing-md);
  }

  .mode-selection h2 {
    font-size: 2rem;
  }

  .local-mode-card {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }

  .mode-content {
    text-align: center;
  }
}

@media (prefers-color-scheme: dark) {
  .local-mode-card {
    background: rgba(255, 255, 255, 0.03);
  }

  .feature-card {
    background: rgba(255, 255, 255, 0.02);
  }

  .google-section {
    background: rgba(255, 255, 255, 0.02);
  }
}

/* RTL Support */
.rtl .mode-content,
.rtl .feature-card {
  text-align: right;
}
