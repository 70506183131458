.careers-page {
  padding: 4rem 0;
}

.careers-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.careers-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.position-card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.position-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.position-card .card-title {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.position-card .card-text {
  display: flex;
  gap: 1.5rem;
  color: var(--text-secondary);
}

.position-card .department,
.position-card .location,
.position-card .type {
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
}

.position-card .department::before,
.position-card .location::before,
.position-card .type::before {
  content: '•';
  margin-right: 0.5rem;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .careers-page {
    padding: 2rem 0;
  }
  
  .position-card .card-text {
    flex-direction: column;
    gap: 0.5rem;
  }
}
