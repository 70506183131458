/* Advanced AI Stylist Homepage Styles */

/* RTL Support */
.homepage.rtl {
    direction: rtl;
}

.homepage.ltr {
    direction: ltr;
}

.homepage {
    font-family: var(--font-primary);
    overflow-x: hidden;
    position: relative;
    padding-top: 80px;
}

/* Intro Overlay - Futuristic Loading Screen */
.intro-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 102, 170, 0.25) 0%, rgba(255, 153, 204, 0.25) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(15px);
    perspective: 1000px;
}

.intro-overlay h1 {
    font-size: 4.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-text-primary);
    letter-spacing: -0.05em;
    text-transform: uppercase;
    transform: translateZ(50px);
    text-shadow: 0 0 20px rgba(255, 102, 170, 0.4);
    text-align: center;
    width: 100%;
}

/* Feature Cards - Glassmorphic Design */
.feature-card {
    background: linear-gradient(135deg, rgba(255, 102, 170, 0.1) 0%, rgba(255, 153, 204, 0.1) 100%);
    border: 1px solid rgba(255, 102, 170, 0.15);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    transition: var(--transition-smooth);
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-shadow: 0 15px 35px rgba(255, 102, 170, 0.15);
}

.rtl .feature-card {
    text-align: right;
}

.ltr .feature-card {
    text-align: left;
}

.feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 102, 170, 0.15) 0%, rgba(255, 153, 204, 0.15) 100%);
    opacity: 0;
    transition: var(--transition-smooth);
}

.feature-card:hover::before {
    opacity: 1;
}

.feature-card:hover {
    transform: translateY(-15px) scale(1.03);
    box-shadow: 0 25px 50px rgba(255, 102, 170, 0.25);
}

.feature-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacing-sm);
    color: var(--color-accent);
    font-size: 3rem;
    transition: var(--transition-smooth);
}

.feature-card:hover .feature-icon {
    transform: scale(1.2) rotate(10deg);
}

.feature-card h5 {
    color: var(--color-text-primary);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-xs);
    transition: var(--transition-smooth);
}

.feature-card p {
    color: var(--color-text-secondary);
    font-size: 0.9rem;
    transition: var(--transition-smooth);
}

/* RTL text alignment */
.rtl .hero-description {
    text-align: right;
}

.ltr .hero-description {
    text-align: left;
}

/* Button container RTL support */
.rtl .btn-container {
    flex-direction: row-reverse;
}

/* RTL margin adjustments */
.rtl .me-auto {
    margin-right: unset !important;
    margin-left: auto !important;
}

.rtl .ms-auto {
    margin-left: unset !important;
    margin-right: auto !important;
}

/* Animation adjustments for RTL */
.rtl .motion-div {
    transform-origin: right center;
}

.ltr .motion-div {
    transform-origin: left center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .rtl .hero-description {
        text-align: right;
    }
    .ltr .hero-description {
        text-align: left;
    }

    .rtl .btn-container,
    .ltr .btn-container {
        flex-direction: column;
    }
}

.hero-description {
    text-align: left;
    direction: ltr;
}

html[dir="rtl"] .hero-description {
    text-align: right;
    direction: rtl;
}

/* Responsive adjustments for RTL */
@media (max-width: 768px) {
    html[dir="rtl"] .hero-description {
        text-align: center;
    }
}

.ai-illustration-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: var(--spacing-lg);
    transition: var(--transition-smooth);
}

.ai-illustration-placeholder:hover {
    transform: scale(1.05) rotate(2deg);
    box-shadow: 0 20px 40px rgba(0,0,0,0.2);
}

/* Button Styling */
.homepage .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm);
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
}

/* Comparison Card Styling */
.comparison-card {
    cursor: pointer;
    transition: var(--transition-smooth);
    padding: var(--spacing-xs);
    text-align: center;
    max-width: 180px;
    margin: 0 auto;
}

.comparison-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
}

.comparison-card .feature-icon {
    margin-bottom: var(--spacing-xxs);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.comparison-card h5 {
    color: var(--color-text-primary);
    margin-top: var(--spacing-xxs);
    font-size: 0.8rem;
}

/* Responsive Design */
@media (max-width: 768px) {

    .feature-card {
        margin-bottom: var(--spacing-sm);
    }
}

/* Mobile Responsive Styles for Feature Cards */
@media (max-width: 768px) {
    .homepage .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .homepage .row > [class*='col-'] {
        width: 90%; /* Slightly less than full width for margins */
        max-width: 400px; /* Prevent cards from becoming too wide */
        margin-bottom: var(--spacing-sm);
        padding-left: var(--spacing-xs);
        padding-right: var(--spacing-xs);
    }

    .feature-card {
        width: flex;
        margin: 0 auto var(--spacing-sm);
    }

    .homepage .btn-container {
        flex-direction: column;
        gap: var(--spacing-sm);
    }

    .homepage .btn-container .btn {
        width: 100%;
        max-width: 300px;
        margin: 0 auto var(--spacing-xs);
    }
}

/* Subtle Animations */
@keyframes subtle-float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
}

.floating-element {
    animation: subtle-float 4s ease-in-out infinite;
}
