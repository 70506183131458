/* Modern Measurements Page Styles for 2024 */

.measurements-page {
    min-height: 100vh;
    color: var(--text-light);
    font-family: 'Inter', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 2rem;
    margin-top: 30px;
}

.measurements-container {
    max-width: 1200px;
    margin: 0 auto;
    background: var(--color-background-floating-button);
    border-radius: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.4);
    padding: 2rem;
    backdrop-filter: blur(10px);
}

.section-tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 1rem;
}

.section-tabs .tab {
    background: transparent;
    border: none;
    color: var(--text-light);
    font-weight: 600;
    padding: 0.75rem 1.5rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.section-tabs .tab.active {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    /* color: white; */
    box-shadow: 0 5px 15px rgba(255, 105, 180, 0.4);
}

.section-tabs .tab:hover {
    transform: scale(1.05);
}

.upload-section {
    text-align: center;
}

.upload-area {
    border: 2px dashed var(--primary-color);
    border-radius: 15px;
    padding: 3rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 2rem 0;
}

.upload-area:hover {
    background: rgba(255, 105, 180, 0.1);
    border-color: var(--secondary-color);
}

.upload-icon {
    font-size: 4rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.images-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 2rem 0;
}

.image-container {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}

.image-container img {
    /* max-width: 200px; */
    /* max-height: 200px; */
    object-fit: cover;
}

.remove-image-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.remove-image-btn:hover {
    background: rgba(255, 0, 0, 0.9);
    transform: scale(1.1);
}

.upload-actions {
    display: flex;
    justify-content: center;
}

.btn-analyze {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    border: none;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(255, 105, 180, 0.3);
}

.btn-analyze:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.preferences-section,
.measurements-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.preference-group,
.measurements-section > div {
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.measurements-section > div:hover {
    transform: scale(1.02);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.preference-grid,
.exclusions-grid,
.attributes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}

.preference-item,
.exclusion-item {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

.preference-item input,
.exclusion-item input {
    margin-right: 1rem;
    accent-color: var(--primary-color);
}

.preference-item.selected,
.exclusion-item.selected {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: var(--text-secondary);
}

.preferences-actions,
.upload-actions {
    display: flex;
    justify-content: center;
}

.btn-save-preferences {
    background: linear-gradient(45deg, var(--accent-color), #FF4E50);
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(255, 110, 110, 0.3);
}

.measurements-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.25rem;
}

.measurement-field {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 1rem;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.measurement-field:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background: rgba(50, 50, 75, 0.8);
}

.measurement-label {
    flex: 1;
    font-weight: 500;
    color: var(--text-light);
    margin-right: 1rem;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.measurement-field:hover .measurement-label {
    opacity: 1;
}

.measurement-input {
    text-align: right;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--text-light);
    padding: 0.625rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    font-weight: 500;
}

.measurement-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(255, 105, 180, 0.2);
    transform: scale(1.02);
}

.measurements-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* RTL Language Support */
[dir="rtl"] .measurement-field {
    flex-direction: row-reverse;
}

[dir="rtl"] .measurement-label {
    margin-right: 0;
    margin-left: 1rem;
    text-align: right;
}

[dir="rtl"] .measurement-input {
    text-align: left;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .measurements-grid {
        grid-template-columns: 1fr;
    }

    .measurement-field {
        flex-direction: column;
        align-items: stretch;
        text-align: center;
    }

    .measurement-label {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

    .measurement-input {
        text-align: center;
    }
}

/* Personal Attributes Grid Layout */
.attributes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.attribute-field {
    display: flex;
    align-items: center;
    padding: 1rem;
    transition: all 0.3s ease;
}

.attribute-label {
    flex: 1;
    text-align: left;
    font-weight: 500;
    color: var(--text-light);
    margin-right: 1rem;
}

.attribute-field select, 
.attribute-field input {
    width: 120px;
    text-align: right;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: var(--text-light);
    padding: 0.5rem;
    border-radius: 5px;
    transition: all 0.3s ease;
    appearance: none;
}

.attribute-field select:focus, 
.attribute-field input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(255, 105, 180, 0.3);
}

/* Custom dropdown arrow */
.attribute-field {
    position: relative;
}

.attribute-field::after {
    content: '▼';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-light);
    pointer-events: none;
    font-size: 0.7rem;
    opacity: 0.5;
}

/* Save Preferences/Measurements Button */
.save-preferences-btn {
    width: 100%;
    margin-top: 1.5rem;
    padding: 1rem;
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(255, 105, 180, 0.2);
}

.save-preferences-btn:hover {
    background: linear-gradient(45deg, var(--secondary-color), var(--primary-color));
    box-shadow: 0 15px 25px rgba(255, 105, 180, 0.3);
}

.save-preferences-btn:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(255, 105, 180, 0.4);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .measurements-container {
        padding: 1rem;
    }

    .section-tabs {
        flex-direction: column;
    }

    .section-tabs .tab {
        justify-content: center;
    }

    .preference-grid,
    .exclusions-grid,
    .measurements-grid,
    .attributes-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .measurements-grid {
        grid-template-columns: 1fr;
    }

    .measurement-input {
        text-align: center;
    }

    .attributes-grid {
        grid-template-columns: 1fr;
    }

    .attribute-field {
        flex-direction: column;
        align-items: stretch;
    }

    .attribute-label {
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .attribute-field select, 
    .attribute-field input {
        width: 100%;
        text-align: center;
    }

    .attribute-field::after {
        right: 50%;
        transform: translate(50%, -50%);
    }
}

.personal-attributes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.25rem;
}

.attribute-field {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 1rem;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.attribute-field:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background: rgba(50, 50, 75, 0.8);
}

.attribute-field label {
    margin-bottom: 0.75rem;
    font-weight: 500;
    color: var(--text-light);
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.attribute-field:hover label {
    opacity: 1;
}

.attribute-field select,
.attribute-field input {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--text-light);
    padding: 0.625rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    font-weight: 500;
}

.attribute-field select:focus,
.attribute-field input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(255, 105, 180, 0.2);
    transform: scale(1.02);
}

.attribute-field select option {
    background: var(--background-dark);
    color: var(--text-light);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .personal-attributes-grid {
        grid-template-columns: 1fr;
    }
}
