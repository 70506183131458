/* File: src/styles/components/ImagesPreview.css */

/* Container for All Image Previews */
.images-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
    width: fit-content;
    margin: auto;
}

/* Individual Image Wrapper */
.image-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid #FFFFFF; /* White border for visibility */
}

/* Preview Image Styling */
.preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Delete Image Button */
.delete-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(255, 0, 0, 0.7); /* Semi-transparent red */
    border: none;
    color: #FFFFFF; /* White icon/text */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.delete-image-button:hover,
.delete-image-button:focus {
    background: rgba(255, 0, 0, 1); /* Solid red on hover/focus */
    outline: none;
}

.delete-image-button:focus-visible {
    outline: 3px dashed #FF69B4; /* Custom focus outline */
}

/* "Clear All" Button */
.images-preview-container .clear-all-button {
    width: 100%;
    max-width: 200px;
    margin-top: 10px;
    padding: 10px;
    background-color: #FF4500; /* Orange-red background */
    color: #FFFFFF; /* White text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.images-preview-container .clear-all-button:hover,
.images-preview-container .clear-all-button:focus {
    background-color: #FF6347; /* Tomato color on hover/focus */
    outline: none;
}

.images-preview-container .clear-all-button:focus-visible {
    outline: 3px dashed #FF69B4; /* Custom focus outline */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
    .image-wrapper {
        width: 100px;
        height: 100px;
    }

    .images-preview-container .clear-all-button {
        max-width: 150px;
        padding: 8px;
        font-size: 0.9rem;
    }
}
