/* Wardrobe Page Global Styles */
.wardrobe-page {
    background-color: var(--background-dark);
    color: var(--text-light);
    font-family: 'Inter', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-height: 100vh;
    margin-top: 3rem;
    padding: 2rem;
}

/* Navigation Tabs */
.wardrobe-tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.tab-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: var(--text-light);
    padding: 0.75rem 1.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
}

.tab-button.active {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: white;
}

.tab-button svg {
    margin-right: 0.5rem;
}

/* Upload Section */
.upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-area {
    max-width: 600px;
    border: 2px dashed var(--color-text-primary);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.upload-area:hover {
    border-color: var(--primary-color);
    background: rgba(0, 200, 255, 0.05);
}

.upload-icon {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.image-preview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
}

.image-preview {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    min-height: 300px;
}

.image-preview img {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: contain;
}

.remove-image-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--color-primary);
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Inventory Section */
.inventory-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.inventory-item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 1rem;
    transition: all 0.3s ease;
}

.item-details-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.item-details-column h3 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--primary-color);
}

.item-details-column p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--text-light);
}

.item-icon-column {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.item-icon {
    width: 4rem;
    height: 4rem;
}

/* Categories Section */
.categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.category-item {
    background: rgba(40, 40, 60, 0.6);
    border-radius: 10px;
    padding: 1rem;
    transition: all 0.3s ease;
}

.category-item h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.category-item ul {
    list-style-type: none;
    padding: 0;
}

.category-item li {
    background: rgba(255, 255, 255, 0.1);
    margin: 0.25rem 0;
    padding: 0.5rem;
    border-radius: 5px;
}

/* Matched Items Styles */
.matched-items {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    background: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius-soft);
    padding: var(--spacing-md);
    box-shadow: var(--shadow-medium);
}

/* Desktop Table View */
.desktop-view {
    display: block;
}

.desktop-view table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: var(--spacing-md);
}

.desktop-view th {
    background: linear-gradient(135deg, var(--color-accent-primary), var(--color-accent-secondary));
    color: var(--color-text-primary);
    padding: var(--spacing-md);
    text-align: left;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.desktop-view th:first-child {
    border-top-left-radius: var(--border-radius-soft);
}

.desktop-view th:last-child {
    border-top-right-radius: var(--border-radius-soft);
}

.desktop-view td {
    padding: var(--spacing-md);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.desktop-view tr:last-child td {
    border-bottom: none;
}

.desktop-view tr:hover td {
    background-color: rgba(255, 255, 255, 0.05);
}

/* Mobile Card View */
.mobile-view {
    display: none;
}

.item-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius-soft);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: var(--shadow-subtle);
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-sm);
    gap: var(--spacing-sm);
}

.input-group label {
    flex: 0 0 35%;
    color: var(--color-text-secondary);
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding-right: var(--spacing-sm);
}

.input-group input {
    flex: 1;
}

.item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
}

.item-icon {
    width: 3.5rem;
    height: 3.5rem;
    margin-left: 1rem;
}

.item-header h3 {
    margin: 0;
    flex-grow: 1;
}

/* Common Input Styles */
.matched-items input {
    width: 100%;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: var(--border-radius-soft);
    padding: var(--spacing-sm) var(--spacing-md);
    color: var(--color-text-primary);
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.matched-items input:focus {
    outline: none;
    border-color: var(--color-accent);
    box-shadow: 0 0 0 2px rgba(184, 90, 255, 0.2);
}

.matched-items input:hover {
    border-color: var(--color-accent-highlight);
}

/* Responsive Design */
@media (max-width: 768px) {
    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }

    .matched-items {
        padding: var(--spacing-sm);
        margin: 1rem 0;
    }

    .item-card {
        margin-bottom: var(--spacing-md);
    }

    .input-group {
        margin-bottom: var(--spacing-md);
    }
}

@media (max-width: 768px) {
    .wardrobe-tabs {
        flex-direction: column;
    }

    .tab-button {
        width: 100%;
        justify-content: center;
    }

    .image-preview-grid,
    .inventory-grid,
    .categories-grid {
        grid-template-columns: 1fr;
    }

    .matched-items {
        padding: var(--spacing-sm);
        margin: 1rem 0;
    }

    .matched-items table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .matched-items th,
    .matched-items td {
        padding: var(--spacing-sm);
    }

    .matched-items input {
        min-width: 120px;
    }
}

/* Save Button Styles */
.save-preferences-btn {
    width: 100%;
    margin-top: 1.5rem;
    padding: 1rem;
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(0, 200, 255, 0.2);
}

.save-preferences-btn:hover {
    background: linear-gradient(45deg, var(--secondary-color), var(--primary-color));
    box-shadow: 0 15px 25px rgba(0, 200, 255, 0.3);
}

.save-preferences-btn:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 200, 255, 0.4);
}

.save-preferences-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
