.tutorials-page {
  padding: 4rem 0;
}

.tutorials-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.tutorials-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.tutorial-card {
  border: none;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  transition: var(--transition-normal);
  overflow: hidden;
}

.tutorial-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.video-preview {
  position: relative;
  height: 200px;
  background: var(--background-light);
  overflow: hidden;
}

.video-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.tutorial-card:hover .video-preview img {
  transform: scale(1.05);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  font-size: var(--font-size-xl);
  transition: var(--transition-fast);
}

.tutorial-card:hover .play-button {
  background: var(--primary-color);
  color: white;
}

.tutorial-card .card-title {
  color: var(--text-primary);
  font-size: var(--font-size-xl);
  margin: 1rem 0;
}

.tutorial-meta {
  display: flex;
  gap: 1.5rem;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.tutorial-meta span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tutorial-sidebar {
  position: sticky;
  top: 2rem;
}

.sidebar-card {
  border: none;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  margin-bottom: 2rem;
}

.sidebar-card h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-list li {
  margin-bottom: 0.75rem;
}

.category-list a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: var(--transition-fast);
  display: flex;
  align-items: center;
}

.category-list a:hover {
  color: var(--primary-color);
  padding-left: 0.5rem;
}

@media (max-width: 992px) {
  .tutorial-sidebar {
    position: static;
    margin-top: 3rem;
  }
}

@media (max-width: 768px) {
  .tutorials-page {
    padding: 2rem 0;
  }
  
  .video-preview {
    height: 180px;
  }
}
