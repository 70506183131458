/* File: src/styles/pages/multiStepMirror.css */


.form-group {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);  /* Adds vertical space between all direct children */
    width: 100%;
    min-width: 80vw;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

select {
    padding: var(--spacing-xs) var(--spacing-sm);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius-soft);
    background-color: color-mix(in srgb, var(--color-secondary) 30%, transparent);
    color: var(--color-text-primary);
    transition: border-color 0.3s;
    text-align: center; /* Center align text */
}

select:focus {
    border-color: var(--color-accent-highlight);
    outline: none; /* Remove default outline */
}

@media (max-width: 768px) {
    select {
        font-size: 14px; /* Smaller font for dropdowns on mobile */
    }

    .btn {
        font-size: 14px; /* Smaller button text on mobile */
    }
}