/* File: src/styles/global.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');
@import url('variables.css');

/* Global Reset and Base Styles */
*, *::before, *::after {
    margin: 0;
    /* padding-top: 0.05rem; */
    /* padding-bottom: 0.05rem; */

}

p {
    margin: var(--spacing-xs) 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

body, html {
    background: var(--color-background) !important;
}

*:not(svg *) {
    /* background: transparent; */
    color: var(--color-text-primary); /* Set default text color !important; */
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin: var(--spacing-md);
    padding-left: 0.25rem;
}

.display-heading {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -0.05em;
}

.subheading {
    font-size: 1.2rem;
    font-weight: 300;
}

/* Button Styles */
.btn {
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: var(--spacing-md) var(--spacing-sm);
    border-radius: var(--border-radius-soft);
    font-weight: 600;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    cursor: pointer;
    background-color: var(--color-accent-primary);
    color: var(--color-text-primary);
}

.btn:hover {
    background-color: var(--color-accent-highlight);
    transform: translateY(-2px);
    box-shadow: var(--shadow-subtle);
}

.btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.btn-primary {
    background: var(--color-accent-primary);
    color: var(--color-text-primary);
    margin: var(--spacing-xs);
}

.btn-primary:hover {
    background: color-mix(in srgb, var(--color-accent-primary) 80%, var(--color-text-primary));
    transform: translateY(-2px);
    box-shadow: var(--shadow-subtle);
}

.btn-secondary {
    background: transparent;
    border: 2px solid var(--color-accent-secondary);
    color: var(--color-text-primary);
}

.btn-secondary:hover {
    background: var(--color-accent-secondary);
    transform: translateY(-2px);
    box-shadow: var(--shadow-subtle);
}

/* Form Styles */
.form-control {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius-soft);
    padding: var(--spacing-xs) var(--spacing-sm);
}

.form-control:focus {
    outline: none;
    border-color: var(--color-accent-highlight);
    box-shadow: 0 0 0 3px rgba(167, 138, 219, 0.2);
}

/* Page Select Styles */
.page-select {
    width: 100%;
    padding: var(--spacing-xs) var(--spacing-sm);
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius-soft);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* background-image: white; */
    background-position: calc(100% - 20px) calc(1em + 2px),
                         calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px,
                     5px 5px;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.3s ease;
}

.page-select:focus {
    outline: none;
    border-color: var(--color-accent-highlight);
    box-shadow: 0 0 0 3px rgba(167, 138, 219, 0.2);
}

.page-select option {
    background: var(--color-background);
    padding: var(--spacing-xs) var(--spacing-sm);
}

.page-select option:disabled {
    color: var(--color-text-secondary);
}

.page-select option:hover {
    background: var(--color-accent-secondary);
}

/* Card Styles */
.card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius-soft);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: var(--shadow-medium);
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.02);
}

/* Utility Classes */
.text-accent {
    color: var(--color-accent-primary);
}

.text-muted {
    color: var(--color-text-secondary);
}

.bg-transparent {
    background: transparent;
}

/* Loading Spinner */
.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 20px auto;
}

.loading-spinner::after {
    content: "";
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: var(--color-accent-primary);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Responsive Typography */
@media (max-width: 768px) {
    .display-heading {
        font-size: 2rem;
    }
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { 
        opacity: 0;
        transform: translateY(20px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}

/* Main Container Sizes */
.main-container {
    align-items: center;
    margin: 0 auto;
    justify-content: flex-start;
    text-align: center;
    box-sizing: border-box;
    margin-top: 75px;
    background: var(--gradient-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

/* Page Description */
.page-description {
    margin: 5px 0;
    font-size: var(--font-size-lg);
}

/* Component Containers */
.controls-container,
.text-result,
.audio-container,
.images-preview {
    width: fit-content;
    min-height: 150px;
    padding: var(--spacing-sm);
    margin: 0 auto;
}

/* Animation for Error Messages */
@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

/* Custom Event Input Styles */
.custom-event-container {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-top: var(--spacing-sm);
    width: 100%;
}

.custom-event-input {
    flex-grow: 1;
    margin-right: var(--spacing-xs);
}

.custom-event-submit {
    flex-shrink: 0;
}

@media (max-width: 768px) {
    video {
        width: 100%;
    }
}

.section-title {
    text-align: center;
}