.enterprise-page {
  padding: 4rem 0;
}

.enterprise-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.enterprise-page .lead {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 3rem;
}

.benefits-section {
  margin: 4rem 0;
}

.benefits-section h2 {
  color: var(--text-primary);
  margin-bottom: 3rem;
  text-align: center;
}

.benefit-item {
  text-align: center;
  padding: 2rem;
  background: var(--background-light);
  border-radius: 16px;
  transition: all 0.3s ease;
  height: 100%;
}

.benefit-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.benefit-item h3 {
  color: var(--text-primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.benefit-item p {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.6;
}

.contact-section {
  background: var(--primary-gradient);
  padding: 4rem 2rem;
  border-radius: 16px;
  color: white;
  margin: 4rem 0;
}

.contact-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.contact-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.contact-section .btn-primary {
  background: white;
  color: var(--primary-color);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.contact-section .btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .enterprise-page {
    padding: 2rem 0;
  }
  
  .benefit-item {
    margin-bottom: 1.5rem;
  }
  
  .contact-section {
    padding: 3rem 1.5rem;
  }
}
